/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import UserMenu from "components/common/UserMenu";
import LeftMenu from "components/common/LeftMenu";
import ReservationHeader from "components/ReservationComponents/Header/ReservationHeader";
import ReserveHistoryPopup from "components/ReservationComponents/Popup/ReserveHistoryPopup/ReserveHistoryPopup";
// import FeedBackRulePopup from "components/SurveyComponents/Popup/FeedBackRulePopup";
import AddLabel from "components/common/AddLabel";
import ToggleColumn from "components/ReservationComponents/ToggleColumn/ToggleColumn";
import ReservationSavePopup from "components/ReservationComponents/Popup/ReservationSavePopup/ReservationSavePopup";
import EducatorSelectionPopup from "components/ReservationComponents/Popup/EducatorSelectionPopup/EducatorSelectionPopup";
import SendNotificationCreateMailPopup from "components/ReservationComponents/Popup/SendNotificationCreateMailPopup/SendNotificationCreateMailPopup";
import EmailHistoryPopup from "components/ReservationComponents/Popup/EmailHistoryPopup/EmailHistoryPopup";
import ReservationList from "components/ReservationComponents/ReservationList/ReservationList";
import AllReserveSurveyPopup from "components/ReservationComponents/Popup/AllReserveSurveyPopup/AllReserveSurveyPopup";
import ViewSurveyFeedbackPopup from "components/ReservationComponents/Popup/ViewSurveyFeedbackPopup/ViewSurveyFeedbackPopup";
import SurveyFeedbackThanksModal from "components/ReservationComponents/Modal/SurveyFeedbackThanksModal";
// import ViewFeedbackEmptyPopup from "components/SurveyComponents/Popup/ViewFeedbackEmptyPopup";
import AllTotalReserveSurveyPopup from "components/ReservationComponents/Popup/AllTotalReserveSurveyPopup/AllTotalReserveSurveyPopup";
import ReserveBreadCrumb from "components/ReservationComponents/BreadCrumb/ReserveBreadCrumb";
import ReserveStatusChange from "components/ReservationComponents/Popup/ReserveStatusChange/ReserveStatusChange";
import ViewMailTemplatePopup from "components/MailTemplateComponents/Popup/ViewMailTemplatePopup";
import CreateSalesOrderPopup from "components/ReservationComponents/Popup/CreateSalesOrderpopup/CreateSalesOrderPopup";
import ViewMailPopup from "components/ConversationComponents/Popup/ViewMailPopup";
import CustomNotification from "components/common/CustomNotification";
import ViewTotalSurveyFeedbackPopup from "components/ReservationComponents/Popup/ViewTotalSurveyFeedbackPopup/ViewTotalSurveyFeedbackPopup";
import ReserveFilter from "components/ReservationComponents/Filter/ReserveFilter";
import QuickUserFormPopup from "components/ReservationComponents/Popup/QuickUserFormPopup/QuickUserFormPopup";
import QuickClientFormPopup from "components/ReservationComponents/Popup/QuickClinetFormPopup.js/QuickClientFormPopup";
import SendGroupMailPopup from "components/ReservationComponents/Popup/SendGroupMailPopup/SendGroupMailPopup";
import ClientEducatorSurveyPopup from "components/ReservationComponents/Popup/ClientEducatorSurveyPopup/ClientEducatorSurveyPopup";
import CsvUploadModal from "components/ReservationComponents/Modal/CsvUploadModal";
const Reservation = () => {
  /* useState hook to download excel file */
  const [downloadExcel, setDownloadExcel] = useState(false);
  /* hook to select columns */
  const [columns, setColumns] = useState([]);
  /* hook to search */
  const [search, setSearch] = useState("");
  /* hook to get number of records */
  const [records, setRecords] = useState(0);
  const [filterTitle, setFilterTitle] = useState("");
  const [filterJsonData, setfilterJsonData] = useState(null);
  const [filterId, setFilterId] = useState(null);
  const [filterDefault, setFilterDefault] = useState(false);
  const [reloadFilterList, setReloadFilterList] = useState(false);
  const [resetFilterData, setResetFilterData] = useState(false);
  const [isSearchExecute, setIsSearchExecute] = useState(false);
  const blockRefSelected = useRef(null); // this will be used to acccess dom element directly
  /* function for checking record count */
  const checkedRecordCount = (selectCount) => {
    // console.log("selectCount", selectCount);
    const blockElementSelect = ReactDOM.findDOMNode(blockRefSelected.current);
    if (blockElementSelect) {
      blockElementSelect.innerHTML = selectCount;
    }
  };
  useEffect(() => {
    document.title = "Reservation"; // Update the title when Register component mounts
  }, []);
  return (
    <>
      {/* -------------------- menu section start ---------------------- */}
      <UserMenu />
      <LeftMenu />
      {/* -------------------- menu section end ---------------------- */}
      <section className="mail-wrapper pt-2">
        <div className="container-fluid px-4">
          {/* ------------ bread crumb section start -------------------- */}
          <ReserveBreadCrumb />
          {/* ------------ bread crumb section end -------------------- */}
          {/* ------------------ header section start ---------------------- */}
          <ReservationHeader
            setDownloadExcel={setDownloadExcel}
            setSearch={setSearch}
            records={records}
            blockRefSelected={blockRefSelected}
            setFilterTitle={setFilterTitle}
            setfilterJsonData={setfilterJsonData}
            setFilterId={setFilterId}
            setFilterDefault={setFilterDefault}
            reloadFilterList={reloadFilterList}
            setReloadFilterList={setReloadFilterList}
            setResetFilterData={setResetFilterData}
            isSearchExecute={isSearchExecute}
            setIsSearchExecute={setIsSearchExecute}
          />
          {/* ------------------ header section end ---------------------- */}
          {/* -------------- toggle column start ---------------------------- */}
          <ToggleColumn setColumns={setColumns} />
          {/* -------------- toggle column end ---------------------------- */}
          {/* ---------------------- filter start ------------------ */}
          <ReserveFilter
            setColumns={setColumns}
            filterTitle={filterTitle}
            setFilterTitle={setFilterTitle}
            filterJsonData={filterJsonData}
            setfilterJsonData={setfilterJsonData}
            setFilterId={setFilterId}
            filterId={filterId}
            filterDefault={filterDefault}
            setFilterDefault={setFilterDefault}
            setReloadFilterList={setReloadFilterList}
            resetFilterData={resetFilterData}
            setResetFilterData={setResetFilterData}
            isSearchExecute={isSearchExecute}
            setIsSearchExecute={setIsSearchExecute}
          />
          {/* ---------------------- filter end ------------------ */}
          {/* ----------- reservation list start --------------- */}
          <ReservationList
            downloadExcel={downloadExcel}
            columns={columns}
            setColumns={setColumns}
            search={search}
            setRecords={setRecords}
            checkedRecordCount={checkedRecordCount}
          />
          {/* ----------- reservation list end --------------- */}
        </div>
      </section>
      {/* ------------------ popup section start ------------------------- */}
      <AllReserveSurveyPopup />
      <ReservationSavePopup />
      <ViewSurveyFeedbackPopup />
      <ViewTotalSurveyFeedbackPopup />
      <EmailHistoryPopup />
      <ReserveHistoryPopup />
      <EducatorSelectionPopup />
      <SendNotificationCreateMailPopup />
      <SurveyFeedbackThanksModal />
      <AllTotalReserveSurveyPopup />
      <AddLabel module={"reserve"} />
      <ReserveStatusChange />
      <ViewMailTemplatePopup />
      <CreateSalesOrderPopup />
      <ViewMailPopup />
      <SendGroupMailPopup />
      <ClientEducatorSurveyPopup />
      {/* ------------------ popup section end ------------------------- */}
      {/* -------------- user form popup start ----------------- */}
      <QuickUserFormPopup />
      <QuickClientFormPopup />
      {/* -------------- user form popup end ----------------- */}
      {/*----------- custom notification component start -----------*/}
      <CustomNotification />
      {/*----------- custom notification component end -----------*/}

      <CsvUploadModal />
    </>
  );
};
export default Reservation;
