/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
// import Link
import { Link, useParams } from "react-router-dom";
import Select from "react-select";

//import api requiremnts
import * as url from "urlhelpers/UrlHelper";
import { getData, putData } from "utils/Gateway";

//import context
import { GlobalProvider } from "context/GlobalContext";

//import translation function for language translation
import { useTranslation } from "react-i18next";

import UserProfileTabBreadCrumb from "../BreadCrumb/UserProfileTabBreadCrumb";
import smtpSecureOptions from "data/Prod/smtpSecureOptions.json";
import {
  getPublicIP,
  getTimeStamp,
  getUserBrowser,
} from "helpers/AuthHelper/AuthHelper";
import CustomNotification from "components/common/CustomNotification";

const SettingsTab = (props) => {
  const token = localStorage.getItem("token");
  const { setSuccess, setError, setNotificationString } =
    useContext(GlobalProvider);
  const params = useParams();

  const { t } = useTranslation(); //for translation

  // smtp config
  const [smtpHost, setSmtpHost] = useState(null);
  const [smtpUser, setSmtpUser] = useState(null);
  const [smtpPassword, setSmtpPassword] = useState(null);
  const [smtpPort, setSmtpPort] = useState(null);
  const [smtpSecure, setSmtpSecure] = useState(null);
  const [useSmtpConfigValue, setUseSmtpConfigValue] = useState(false);
  const [useSmtpConfig, setUseSmtpConfig] = useState(null);

  // sendgrid config
  const [sendgridmail, setSendgridmail] = useState(null);
  const [sendgridkey, setSendgridkey] = useState(null);
  const [usesendgrid, setUsesendgrid] = useState(null);
  const [usesendgridSelectOption, setUsesendgridSelectOption] = useState(null);

  //out of field requirements
  const [surname, setsurname] = useState("");
  const [telephone, setTelephone] = useState("");
  const [position, setPosition] = useState("");
  const [zipcode, setzipcode] = useState("");
  const [stateName, setstateName] = useState("");
  const [cityName, setcityName] = useState("");
  const [country, setcountry] = useState("");
  const [address, setAddress] = useState("");
  const [language, setlanguage] = useState("");
  const [latitude, setlatitude] = useState("");
  const [longitude, setlongitude] = useState("");
  const [mapcoordinate, setmapcoordinate] = useState("");
  const [currency, setcurrency] = useState("");

  const [isSaving, setisSaving] = useState(false);

  //function for update user profile
  const updateUserHandler = async () => {
    const detectedBrowser = getUserBrowser();
    const userPublicIp = await getPublicIP();

    const { loginDate, loginTime, loginTimeStamp } = getTimeStamp();

    let userData = {
      surname,
      position,
      address,
      city: cityName,
      state: stateName,
      country,
      zipcode,
      phone: telephone,
      language,
      currency,
      mapcoordinate,
      latitude,
      longitude,
      browser: detectedBrowser,
      userip: userPublicIp,

      smtphost: smtpHost && smtpHost.length > 2 ? smtpHost.trim() : null,
      smtpuser: smtpUser && smtpUser.length > 2 ? smtpUser.trim() : null,
      smtppass:
        smtpPassword && smtpPassword.length > 2 ? smtpPassword.trim() : null,
      smtpport: smtpPort && smtpPort.length > 1 ? smtpPort.trim() : null,
      smtpsecure: smtpSecure ? smtpSecure.value : null,
      usesmtpmail: useSmtpConfig ? useSmtpConfig.value : null,

      sendgridmail:
        sendgridmail && sendgridmail.length > 2 ? sendgridmail.trim() : null,
      sendgridkey:
        sendgridkey && sendgridkey.length > 2 ? sendgridkey.trim() : null,
      usesendgrid: usesendgridSelectOption
        ? usesendgridSelectOption.value
        : null,
    };

    // console.log(`userData`, userData);

    try {
      setisSaving(true);

      let requestUrl =
        url.API_BASEURL +
        url.API_UPDATE_USER +
        `/${params.id}` +
        `?token=${token}`;

      // console.log("personal info tab url----->", requestUrl);

      const response = await putData(requestUrl, userData);

      // console.log("response personal info tab---->", response);
      setisSaving(false);
      if (response.status) {
        props.afterUpdatePasswordTab();
        setSuccess(true);
        setNotificationString(
          t("Personal settings has been updated successfully")
        );
      }
    } catch (error) {
      console.error(error.message);
      setError(true);
      setNotificationString(t("Something went wrong, please try again later"));
    }
    // } else {
    //   setError(true);
    //   setNotificationString(t('Email does not exist'));
    // }
  };

  useEffect(() => {
    if (props.userData) {
      // mail configuration -----------------
      if (props.userData?.smtphost && props.userData?.smtphost.length > 0) {
        setSmtpHost(props.userData?.smtphost);
      }
      if (props.userData.smtpuser && props.userData?.smtpuser.length > 0) {
        setSmtpUser(props.userData?.smtpuser);
      }

      if (props.userData?.smtppass && props.userData?.smtppass.length > 0) {
        setSmtpPassword(props.userData?.smtppass);
      }
      if (props.userData?.smtpport && props.userData?.smtpport.length > 0) {
        setSmtpPort(props.userData?.smtpport);
      }

      if (
        props.userData?.sendgridmail &&
        props.userData?.sendgridmail.length > 0
      ) {
        setSendgridmail(props.userData?.sendgridmail);
      }

      if (
        props.userData?.sendgridkey &&
        props.userData?.sendgridkey.length > 0
      ) {
        setSendgridkey(props.userData?.sendgridkey);
      }

      for (let smtpSecureOption of smtpSecureOptions) {
        // set secure smtp connect option YES/NO
        if (smtpSecureOption.value == props.userData?.smtpsecure) {
          setSmtpSecure(smtpSecureOption);
        } else {
          if (smtpSecureOption.value == false) {
            setSmtpSecure(smtpSecureOption);
          }
        }

        // set either to use this config or not, YES / NO
        if (smtpSecureOption.value == props.userData?.usesmtpmail) {
          setUseSmtpConfig(smtpSecureOption);
          setUseSmtpConfigValue(smtpSecureOption.value);
        } else {
          if (smtpSecureOption.value == false) {
            setUseSmtpConfig(smtpSecureOption);
            setUseSmtpConfigValue(smtpSecureOption.value);
          }
        }

        // use sendgrid
        if (smtpSecureOption.value == props.userData?.usesendgrid) {
          setUsesendgridSelectOption(smtpSecureOption);
          setUsesendgrid(smtpSecureOption.value);
        } else {
          if (smtpSecureOption.value == false) {
            setUsesendgridSelectOption(smtpSecureOption);
            setUsesendgrid(smtpSecureOption.value);
          }
        }
      }

      if (props.userData?.surname) {
        setsurname(props.userData?.surname);
      }

      if (props.userData?.phone) {
        setTelephone(props.userData?.phone);
      }

      if (props.userData?.position) {
        setPosition(props.userData?.position);
      }

      if (props.userData?.zipcode) {
        setzipcode(props.userData?.zipcode);
      }

      if (props.userData?.state) {
        setstateName(props.userData?.state);
      }

      if (props.userData?.city) {
        setcityName(props.userData?.city);
      }

      if (props.userData?.country) {
        setcountry(props.userData?.country);
      }

      if (props.userData?.address) {
        setAddress(props.userData?.address);
      }

      if (props.userData?.language) {
        setlanguage(props.userData?.language);
      }

      if (props.userData?.latitude) {
        setlatitude(props.userData?.latitude);
      }

      if (props.userData?.longitude) {
        setlongitude(props.userData?.longitude);
      }

      if (props.userData?.mapcoordinate) {
        setmapcoordinate(props.userData?.mapcoordinate);
      }

      if (props.userData?.currency) {
        setcurrency(props.userData?.currency);
      }
    }
  }, [props.userData]);

  return (
    <div className="profile_tabInnr">
      <UserProfileTabBreadCrumb
        moduleName="Settings"
        userData={props?.userData}
      />

      <div className="profile_graybx_Outer">
        <div className="profile_graybx_item">
          <div className="profile_fillOuter">
            {/* Mail sending configuration for this user ---------------------- */}
            <h4 className="mb-3">
              {t("SMTP Configuration (for sending mails)")}
            </h4>

            <div className="profile_fill_row profile_fill_row_settings">
              <label>{t("SMTP Host")}</label>
              <input
                type="text"
                className="form-control"
                placeholder={t("SMTP Host")}
                value={smtpHost}
                onChange={(e) => setSmtpHost(e.target.value)}
              />
            </div>

            <div className="profile_fill_row profile_fill_row_settings">
              <label>{t("SMTP User")}</label>
              <input
                type="text"
                className="form-control"
                placeholder={t("SMTP User Email")}
                value={smtpUser}
                onChange={(e) => setSmtpUser(e.target.value)}
              />
            </div>

            <div className="profile_fill_row profile_fill_row_settings">
              <label>{t("SMTP Password")}</label>
              <input
                type="text"
                className="form-control"
                placeholder={t("SMTP Password")}
                value={smtpPassword}
                onChange={(e) => setSmtpPassword(e.target.value)}
              />
            </div>

            <div className="profile_fill_row profile_fill_row_settings">
              <label>{t("SMTP Port")}</label>
              <input
                type="text"
                className="form-control"
                placeholder={t("587 for non-SSL, 465 for SSL")}
                value={smtpPort}
                onChange={(e) => setSmtpPort(e.target.value)}
              />
            </div>

            <div className="profile_fill_row profile_fill_row_settings">
              <label>{t("Is secure connection?")}</label>
              <Select
                className="w-100"
                placeholder={t("true if SSL/TLS")}
                options={smtpSecureOptions}
                value={smtpSecure}
                onChange={(val) => {
                  setSmtpSecure(val);
                }}
              />
            </div>

            <div className="profile_fill_row profile_fill_row_settings">
              <label>{t("Use this configuration to send mails")}</label>
              <Select
                className="w-100"
                placeholder={t("true if SSL/TLS")}
                options={smtpSecureOptions}
                value={useSmtpConfig}
                onChange={(val) => {
                  setUseSmtpConfig(val);
                }}
              />
            </div>

            {/* SEND GRID  ---------------------- */}
            {/* <h4 className="my-3">
              {t("SendGrid Configuration (for sending mails)")}
            </h4> */}

            {/* <div className="profile_fill_row profile_fill_row_settings">
              <label>SendGrid {t("Email")}</label>
              <input
                type="text"
                className="form-control"
                placeholder={t("SendGrid Email")}
                value={sendgridmail}
                onChange={(e) => setSendgridmail(e.target.value)}
              />
            </div> */}

            {/* <div className="profile_fill_row profile_fill_row_settings">
              <label>SendGrid {t("API Key")}</label>
              <input
                type="text"
                className="form-control"
                placeholder={t("API Key")}
                value={sendgridkey}
                onChange={(e) => setSendgridkey(e.target.value)}
              />
            </div> */}

            {/* <div className="profile_fill_row profile_fill_row_settings align-items-center">
              <label>{t("Use SendGrid for sending mail")}</label>
              <Select
                className="w-100"
                options={smtpSecureOptions}
                value={usesendgridSelectOption}
                onChange={(val) => {
                  setUsesendgridSelectOption(val);
                }}
              />
            </div> */}

            <div className="action">
              <button
                type="button"
                className="btn btn-primary"
                onClick={updateUserHandler}
                disabled={isSaving ? true : false}
                style={{
                  cursor: isSaving ? "not-allowed" : "pointer",
                }}
              >
                {t("Save Settings")}
                {isSaving && (
                  <div
                    className="mx-2 spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* custom notification component */}
      <CustomNotification />
    </div>
  );
};

export default SettingsTab;
