/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";

/* Importing react select */
import Select from "react-select";

// Import CKEditor here (both required).
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

//import translation function for language translation
import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import { postData, getData, uploadMultipleFile } from "utils/Gateway";
import * as url from "urlhelpers/UrlHelper";

// clipboardCopy for mail variables
import clipboardCopy from "clipboard-copy";

// import mail variables from json file
import mailVariables from "data/Prod/MailVariables.json";

//import file download function
import downloadFileHandler from "helpers/DownloadFile";

//import context
import { GlobalProvider } from "context/GlobalContext";

//send mail from options
import SendMailOptions from "data/Prod/SendMailFromOptions.json";

const CreateEmailPopup = () => {
  const token = localStorage.getItem("token"); // token

  const { t } = useTranslation(); //for translation

  // loggedin user information
  const currentUser = JSON.parse(localStorage.getItem("userInfo"));

  // context data
  const {
    setReloadConversationList,
    setSuccess,
    setNotificationString,
    setError,
  } = useContext(GlobalProvider);

  // show wait message
  const [showWaitMessage, setShowWaitMessage] = useState(false);
  const [mailMessage, setMailMessage] = useState(
    "Please wait while we send your mail to recepient"
  );

  /*---- CKEditor classic editor configuration----- */
  const editorConfig = {
    toolbar: ["bold", "italic", "link", "bulletedList", "numberedList"],
    ckfinder: {
      // Upload the images to the server using the CKFinder QuickUpload command.
      //   uploadUrl: url.URL_TEXT_EDITOR_IMAGE_UPLOAD_2,
    },
    placeholder: t("Type here..."),
  };

  const [selectedSendMailFromValue, setSelectedSendMailFromValue] =
    useState(null);
  const [selectedSendMailFrom, setSelectedSendMailFrom] = useState("");

  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [templateList, setTemplateList] = useState([]);

  const [editorData, setEditorData] = useState("");

  const [isUploading, setIsUploading] = useState(false);
  const [fileUploadIds, setFileUploadIds] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  /* hooks for inputs */
  const [userInputs, setUserInputs] = useState({
    receivermails: "",
    sender: "",
    reservation: "",
    messagetemplate: "",
    subject: "",
    description: "",
  });

  /* hook for validation */
  const [validation, setValidation] = useState({
    emailWarning: false,
    subjectWarning: false,
    descWarning: false,
  });

  /* Template selection */
  const getAllTemplates = async () => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_ALL_TEMPLATE +
        `?token=${token}&isselect=${true}`;

      const response = await getData(requestUrl);

      console.log("template response ==>", response);

      if (response.status) {
        setTemplateList(response.data);
      }
    } catch (error) {}
  };

  //function for file upload
  const uploadHandler = async (e) => {
    try {
      setIsUploading(true);
      let requestUrl =
        url.API_BASEURL + url.API_FILE_UPLOAD_MULTIPLE + `?token=${token}`;

      const response = await uploadMultipleFile(requestUrl, e.target.files);

      if (response.status) {
        console.log(`response`, response.data);

        /*----- assign uploaded files with path for display start ------*/
        const uploadedFileValues = response.data.map(
          ({ name, path, _id, ...other }) => ({
            name,
            path: url.SERVER_URL + path,
            _id,
          })
        );

        //check old file exist or not if exist then new file will concat with old file
        if (uploadedFiles.length == 0) {
          setUploadedFiles(uploadedFileValues);
        } else {
          let fileArr = uploadedFiles.concat(uploadedFileValues);
          setUploadedFiles(fileArr);
        }
        /*----- assign uploaded files with path for display end ------*/

        setIsUploading(false);

        /*----- assign uploaded file ids start ------*/
        const fileIds = response.data.map(({ _id, ...other }) => ({ _id }));

        //check old file ids are exist or not.If exist then new id will concat with old id
        if (fileUploadIds.length == 0) {
          setFileUploadIds(fileIds);
        } else {
          let fileArr = fileUploadIds.concat(fileIds);
          setFileUploadIds(fileArr);
        }
        /*----- assign uploaded file ids end ------*/
        resetFile();
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // validate =========================
  const validate = () => {
    let isValid = true;

    if (userInputs.receivermails === "") {
      setValidation((prevState) => ({ ...prevState, emailWarning: true }));
      setShowWaitMessage(false);
      isValid = false;
    }

    if (userInputs.subject === "") {
      setValidation((prevState) => ({ ...prevState, subjectWarning: true }));
      setShowWaitMessage(false);
      isValid = false;
    }

    // if (editorData === '') {
    //   setValidation(prevState => ({ ...prevState, descWarning: true }));
    //   setShowWaitMessage(false);
    //   isValid = false;
    // }

    return isValid;
  };

  //function for select send mail from
  const sendMailSelectionHandler = (val) => {
    if (val) {
      setSelectedSendMailFromValue(val);
      setSelectedSendMailFrom(val.value);
    } else {
      setSelectedSendMailFromValue(null);
      setSelectedSendMailFrom("");
    }
  };

  // function for clear file value
  const resetFile = () => {
    const file = document.getElementById("uploadedMailFile");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  //function for delete file from upload stack
  const closeFileHandler = (index) => {
    const closedFile = [...uploadedFiles];
    const removedFileId = [...fileUploadIds];

    closedFile.splice(index, 1);
    removedFileId.splice(index, 1);

    setUploadedFiles(closedFile);
    setFileUploadIds(removedFileId);

    resetFile();
  };

  //function for select template
  const templateSelectionHandler = (val) => {
    //assign template value for select
    setSelectedTemplate(val);

    //set value for send mail
    setUserInputs((prevState) => ({
      ...prevState,
      subject: val.subject,
      description: val.description,
    }));

    //assign uploaded files from mail template
    const uploadedFileValues = val.uploads.map(
      ({ name, path, _id, ...other }) => ({
        name,
        path: url.SERVER_URL + path,
        _id,
      })
    );

    if (uploadedFiles.length == 0) {
      setUploadedFiles(uploadedFileValues);
    } else {
      let fileArr = uploadedFiles.concat(uploadedFileValues);
      setUploadedFiles(fileArr);
    }

    //assign uploaded file id for sending mail
    const fileIds = val.uploads.map(({ _id, ...other }) => ({ _id }));

    if (fileUploadIds.length == 0) {
      setFileUploadIds(fileIds);
    } else {
      let fileArr = fileUploadIds.concat(fileIds);
      setFileUploadIds(fileArr);
    }
  };

  /* reset */
  const resetHandler = () => {
    resetFile();
    setEditorData("");
    setShowWaitMessage(false);
    setSelectedTemplate(null);
    setUserInputs({
      receivermails: "",
      sender: currentUser.id,
      reservation: "",
      messagetemplate: "",
      subject: "",
      description: "",
    });
    setUploadedFiles([]);
    setFileUploadIds([]);
    setValidation({
      emailWarning: false,
      subjectWarning: false,
      descWarning: false,
    });
    for (let sendMailData of SendMailOptions) {
      if (sendMailData.value === "amb") {
        sendMailSelectionHandler(sendMailData);
      }
    }
  };

  /* function to add label */
  const saveMailHandler = async (e) => {
    e.preventDefault();

    setShowWaitMessage(true);

    let templateId = selectedTemplate ? selectedTemplate.value : null;
    let mailData = {
      sendmailfrom: selectedSendMailFrom,
      receivermails: userInputs.receivermails,
      subject: userInputs.subject,
      sender: currentUser._id,
      description: editorData,
      messagetemplate: templateId,
      uploads: fileUploadIds,
    };

    console.log("mailData=======>", mailData);

    if (validate()) {
      try {
        let requestUrl =
          url.API_BASEURL + url.API_ADD_MESSAGE + `?token=${token}`;

        const response = await postData(requestUrl, mailData);

        console.log("Mail Response", response);

        if (response.status) {
          resetHandler();
          setNotificationString(t("Mail send successfully"));
          setSuccess(true);
          setReloadConversationList(true);
          setShowWaitMessage(false);
        } else {
          setMailMessage(response.message);
        }
      } catch (error) {
        console.error(error.message);
        setNotificationString(
          t("Something went wrong, please try again later")
        );
        setError(true);
      }

      window.bootstrap.Offcanvas.getInstance(
        document.querySelector("#createEmailPopup")
      ).hide();
    }
  };

  useEffect(() => {
    getAllTemplates();
    for (let sendMailData of SendMailOptions) {
      if (sendMailData.value === "amb") {
        sendMailSelectionHandler(sendMailData);
      }
    }
  }, []);

  return (
    <div
      className="offcanvas offcanvas-end"
      tabIndex="-1"
      id="createEmailPopup"
      aria-labelledby="createEmailPopupLabel"
    >
      <div className="offcanvas-header p-4">
        <h3 className="text-secondary mb-0" id="createEmailPopupLabel">
          {t("Create a Mail")}
        </h3>
        {/* ------- close pop up button start ------- */}
        <button
          type="button"
          className="btn-close p-2 bg-white shadow-none shadow-sm m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
        {/* ------- close pop up button end ------- */}
      </div>
      <div className="offcanvas-body p-4 pt-0">
        {/* ------- pop up form section start ------- */}
        <form onSubmit={saveMailHandler}>
          {/* <!-- ========== Start send mail from Section ========== --> */}
          <div className="form-group mb-3">
            <label htmlFor="selectMailTemplate">{t("Send From")}</label>
            <Select
              className="nosearch_select"
              placeholder={t("Select send mail from")}
              options={SendMailOptions}
              value={selectedSendMailFromValue}
              onChange={(val) => {
                sendMailSelectionHandler(val);
              }}
            />
          </div>
          {/* <!-- ========== End send mail from Section ========== --> */}

          {/* ------- send section start ------- */}
          <label htmlFor="selectMailTemplate">{t("Send To")}</label>
          <div className="form-group mb-3">
            <input
              type="text"
              id="email"
              minLength="5"
              className="form-control"
              placeholder={t("Enter mail address")}
              value={userInputs.receivermails}
              onChange={(val) => {
                setUserInputs((prevState) => ({
                  ...prevState,
                  receivermails: val.target.value,
                }));

                setValidation((prevState) => ({
                  ...prevState,
                  emailWarning: false,
                }));
              }}
            />
            {/* receive mail warning */}
            {validation.emailWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please enter a mail id")}!</span>
                </p>
              </div>
            )}
          </div>
          {/* ------- send section end ------- */}

          {/* ------- select template section start ------- */}
          <div className="form-group mb-3">
            <label htmlFor="selectMailTemplate">
              {t("Select Mail Template")}
            </label>
            <Select
              className="nosearch_select"
              placeholder={t("Select a mail template")}
              options={templateList}
              value={selectedTemplate}
              onChange={(val) => {
                templateSelectionHandler(val);
              }}
            />
          </div>
          {/* ------- select template section end ------- */}

          {/* ------- mail variable select start ------ */}
          <div className="form-group mb-3">
            <div className="row">
              <div className="col-lg-6">
                <label>{t("Mail Variable")}</label>

                <Select
                  className="nosearch_select"
                  placeholder={t("Select mail variables")}
                  options={mailVariables}
                  onChange={(event) => {
                    console.log(event);
                    clipboardCopy(event.value);
                  }}
                />
              </div>
              <div className="col-lg-6">
                <div className="alert alert-light" role="alert">
                  {t(
                    "Select the mail variable you want to add. Next just paste where you want it use it."
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* ------- mail variable select end ------ */}

          {/* ------- subject section start ------- */}
          <div className="form-group mb-3">
            <label htmlFor="mailsubject">{t("Subject")}</label>
            <input
              type="text"
              className="form-control"
              placeholder={t("Subject")}
              value={userInputs.subject}
              onChange={(val) => {
                setUserInputs((prevState) => ({
                  ...prevState,
                  subject: val.target.value,
                }));
                setValidation((prevState) => ({
                  ...prevState,
                  subjectWarning: false,
                }));
              }}
            />
            {/* subject warning */}
            {validation.subjectWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please enter a subject")}!</span>
                </p>
              </div>
            )}
          </div>
          {/* ------- subject section end ------- */}

          {/* ------- mail description editor section start ------- */}
          <div className="form-group mb-3">
            <CKEditor
              className="form-control"
              editor={ClassicEditor}
              config={editorConfig}
              data={userInputs.description}
              onReady={(editor) => {
                editor.editing.view.change((writer) => {
                  writer.setStyle(
                    "height",
                    "300px",
                    editor.editing.view.document.getRoot()
                  );
                });
              }}
              onChange={(event, editor) => {
                let editorContent = editor.getData();
                setEditorData(editorContent);

                setValidation((prevState) => ({
                  ...prevState,
                  descWarning: false,
                }));
              }}
            />
            {/* description warning */}
            {/* {validation.descWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter a description')}!</span>
                </p>
              </div>
            )} */}
          </div>
          {/* ------- mail description editor section end ------- */}

          {/* ========= file upload start ========== */}
          <div className="form-group mb-4">
            {isUploading ? (
              <div className="d-flex align-items-center gap-1 mb-1">
                <p className="fw-bold">{t("Please wait while uploading")}</p>{" "}
                {[1, 2, 3, 4, 5, 6, 7, 8].map((count, index) => {
                  return (
                    <div
                      key={index}
                      className="spinner-grow spinner-grow-sm"
                      role="status"
                      style={{ height: "6px", width: "6px" }}
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  );
                })}
              </div>
            ) : (
              <label>{t("Attachments")}</label>
            )}

            <input
              type="file"
              className="form-control"
              id="uploadedMailFile"
              multiple
              onChange={uploadHandler}
            />

            {uploadedFiles.length > 0 && (
              <ul className="uploaded-files d-flex flex-wrap gap-3 p-0 m-0 mt-3">
                {uploadedFiles.map((file, index) => {
                  return (
                    <li
                      className="file d-flex gap-3 align-items-center px-3 py-2 border border-gray rounded-3"
                      key={index}
                    >
                      <Link to="#" onClick={() => downloadFileHandler(file)}>
                        <span className="d-block material-symbols-outlined">
                          download
                        </span>
                      </Link>
                      <p className="fw-medium">{file.name}</p>
                      <Link to="#" onClick={() => closeFileHandler(index)}>
                        <span className="d-block material-symbols-outlined">
                          close
                        </span>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
          {/* ========= file upload end ===========*/}

          <div className="action d-flex align-items-center gap-2">
            {showWaitMessage && (
              <span>
                <b>{t(mailMessage)}</b>
              </span>
            )}

            {!showWaitMessage && (
              <button type="submit" className="btn btn-primary mw-unset">
                <span className="d-block material-symbols-outlined icon-fill">
                  send
                </span>
              </button>
            )}
          </div>
        </form>
        {/* ------- pop up form section end ------- */}
      </div>
    </div>
  );
};

export default CreateEmailPopup;
