/* eslint-disable */
import React, { useState, useEffect, useContext, createRef } from "react";
// kendo react modules ==========================
import "@progress/kendo-theme-material/dist/all.css";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
// Import kendo react excel
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
// import demodata =============================
import { Link } from "react-router-dom";
//import api requiremnts
import * as url from "urlhelpers/UrlHelper";
import { getData, postData, putData } from "utils/Gateway";
//import context
import { GlobalProvider } from "context/GlobalContext";
//import translation function for language translation
import { useTranslation } from "react-i18next";
//import file download function
import downloadFileHandler from "helpers/DownloadFile";
// Globalization ------------------------
import {
  IntlProvider,
  load,
  LocalizationProvider,
  loadMessages,
} from "@progress/kendo-react-intl";
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/es/numbers.json";
import currencies from "cldr-numbers-full/main/es/currencies.json";
import caGregorian from "cldr-dates-full/main/es/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/es/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/es/timeZoneNames.json";
load(
  likelySubtags,
  currencyData,
  weekData,
  numbers,
  currencies,
  caGregorian,
  dateFields,
  timeZoneNames
);
// import other translations for spainish
import esMessages from "data/Prod/esCalendarMessages.json";
loadMessages(esMessages, "es-ES");
const ReservationList = ({
  downloadExcel,
  columns,
  setColumns,
  search,
  setRecords,
  checkedRecordCount,
}) => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const { t, i18n } = useTranslation(); // translation
  const {
    /*----- loading -----*/
    isReserveListLoading,
    setIsReserveListLoading,
    /*------ reload requirements start -------*/
    reloadReservationList,
    setReloadReservationList,
    /*------ reload requirements end -------*/
    /* -------- reload refresh start --------- */
    reloadRefresh,
    setReloadRefresh,
    /* -------- reload refresh end --------- */
    /*------ edit requirements start -------*/
    setEditReserveData,
    setChangeReserveEducatorData,
    setAllowSelectEducator,
    /*------ edit requirements end -------*/
    //for custom reseve
    setReserveIdNumber,
    setCustomReserveId,
    //for survey
    setReservePersonData,
    //for reserve emails
    setReservationEmailHistoryData,
    //for copy reseve data
    setCopyReserveData,
    //for consecutive
    setaddMoreConsecutiveReserveData,
    /*------ search requirements start -------*/
    searchReserveDate,
    searchReserveIdList,
    searchReserveAmbIdList,
    searchReserveActivity,
    searchReserveLabels,
    searchReserveBenificiaries,
    searchReserveClients,
    searchReserveExcludeClients,
    searchReserveEducators,
    searchReserveStatus,
    setSearchReserveDate,
    setSearchReserveIdList,
    setSearchReserveActivity,
    setSearchReserveLabels,
    setSearchReserveBenificiaries,
    setSearchReserveClients,
    setSearchReserveEducators,
    setSearchReserveStatus,
    isConsecutiveReserveFilter,
    setisConsecutiveReserveFilter,
    unsentMailReserveFilter,
    setUnsentMailReserveFilter,
    setReserveSurveyData,
    clientFeedbackEducatorData,
    setClientFeedbackEducatorData,
    /*------ search requirements end -------*/
    screenHeight,
    setSuccess,
    setNotificationString,
    setError,
  } = useContext(GlobalProvider);

  const [reservationList, setReservationList] = useState([]);
  const [responseReceived, setResponseReceived] = useState(false);
  // without pagination ------------------------------------
  const initialDataState = {};
  const [dataState, setDataState] = useState(null);
  const [result, setResult] = useState(
    process(reservationList, initialDataState)
  );
  /* Handle the button's onClick event and set it to export the data by using the ExcelExport save method. */
  const _exporter = createRef();
  //function for export excel
  const exportExcel = () => {
    if (_exporter.current) {
      _exporter.current.save();
    }
  };
  //use state for adding css in table div
  const [searchContainerClass, setSearchContainerClass] = useState("");

  /* function to search */
  const filterData = () => {
    if (!result || !result.data) {
      return []; // Return an empty array if result or result.data is undefined
    }
    const filteredData = result.data.filter((item) => {
      const reserveid = item?.reserveid?.toString();
      const ambreservationid = item?.ambreservationid;
      const reservedate = item?.reservedate;
      const reserveday = item?.reserveday?.toLowerCase();
      const starttime = item?.starttime;
      const endtime = item?.endtime;
      const activitycode = item?.activitycode?.code?.toLowerCase();
      const activityname = item?.activitycode?.name?.toLowerCase();
      const activitytype = item?.activitytype?.toLowerCase();
      const tagstring = item?.tagstring?.toLowerCase();
      const educator1 = item?.educator1?.name?.toLowerCase();
      const educator2 = item?.educator2?.name?.toLowerCase();
      const client = item?.client?.name?.toLowerCase();
      const observer = item?.observer?.name?.toLowerCase();
      const responsible = item?.responsible?.name?.toLowerCase();
      const beneficiary = item?.beneficiary?.name?.toLowerCase();
      const participantcount = item?.participantcount;
      const educatorcount = item?.educatorcount;
      const realparticipantcount = item?.realparticipantcount;
      const tutor = item?.tutor;
      const grade = item?.grade?.toLowerCase();
      const salesorder = item?.salesorder?.toString();
      const comments = item?.comments?.toLowerCase();
      const internalcomment = item?.internalcomment?.toLowerCase();
      const location = item?.location?.toLowerCase();
      const zipcode = item?.zipcode;
      const reservestatus = item?.reservestatus?.toLowerCase();
      return (
        (reserveid && reserveid.includes(search)) ||
        (ambreservationid &&
          typeof ambreservationid === "string" &&
          ambreservationid.includes(search)) ||
        (reservedate && reservedate.includes(search)) ||
        (reserveday && reserveday.includes(search)) ||
        (starttime && starttime.includes(search)) ||
        (endtime && endtime.includes(search)) ||
        (activitycode && activitycode.includes(search.toLowerCase())) ||
        (activityname && activityname.includes(search.toLowerCase())) ||
        (activitytype && activitytype.includes(search.toLowerCase())) ||
        (tagstring && tagstring.includes(search.toLowerCase())) ||
        (educator1 && educator1.includes(search.toLowerCase())) ||
        (educator2 && educator2.includes(search.toLowerCase())) ||
        (client && client.includes(search.toLowerCase())) ||
        (observer && observer.includes(search.toLowerCase())) ||
        (beneficiary && beneficiary.includes(search.toLowerCase())) ||
        (responsible && responsible.includes(search.toLowerCase())) ||
        (participantcount && participantcount.toString().includes(search)) ||
        (educatorcount && educatorcount.toString().includes(search)) ||
        (realparticipantcount &&
          realparticipantcount.toString().includes(search)) ||
        (comments && comments.includes(search.toLowerCase())) ||
        (internalcomment && internalcomment.includes(search.toLowerCase())) ||
        (tutor && tutor.includes(search)) ||
        (grade && grade.includes(search.toLowerCase())) ||
        (salesorder && salesorder.includes(search)) ||
        (location && location.includes(search.toLowerCase())) ||
        (zipcode && zipcode.includes(search)) ||
        (reservestatus && reservestatus.includes(search.toLowerCase()))
      );
    });
    setRecords(filteredData.length);
    return filteredData;
  };

  /* function for get all reservation */
  const getAllReservationList = async () => {
    try {
      setIsReserveListLoading(true);
      setAllowSelectEducator(true);
      let requestUrl =
        url.API_BASEURL + url.API_GET_ALL_RESERVE_EVENTS + `?token=${token}`;
      if (searchReserveDate != "") {
        requestUrl = requestUrl + `&eventdate=${searchReserveDate}`;
      } else {
        requestUrl = requestUrl;
      }
      if (searchReserveIdList.length > 0) {
        requestUrl = requestUrl + `&reserveids=${searchReserveIdList}`;
      } else {
        requestUrl = requestUrl;
      }
      if (searchReserveAmbIdList.length > 0) {
        requestUrl = requestUrl + `&reserveambids=${searchReserveAmbIdList}`;
      } else {
        requestUrl = requestUrl;
      }
      if (searchReserveActivity.length > 0) {
        requestUrl = requestUrl + `&reserveactivity=${searchReserveActivity}`;
      } else {
        requestUrl = requestUrl;
      }
      if (searchReserveLabels.length > 0) {
        requestUrl = requestUrl + `&reservelabels=${searchReserveLabels}`;
      } else {
        requestUrl = requestUrl;
      }
      if (searchReserveBenificiaries.length > 0) {
        requestUrl =
          requestUrl + `&reservebenificiary=${searchReserveBenificiaries}`;
      } else {
        requestUrl = requestUrl;
      }
      if (searchReserveClients.length > 0) {
        requestUrl = requestUrl + `&reserveclients=${searchReserveClients}`;
      } else {
        requestUrl = requestUrl;
      }
      if (searchReserveExcludeClients.length > 0) {
        requestUrl =
          requestUrl + `&reserveexcludeclients=${searchReserveExcludeClients}`;
      } else {
        requestUrl = requestUrl;
      }
      if (searchReserveEducators.length > 0) {
        requestUrl = requestUrl + `&reserveeducators=${searchReserveEducators}`;
      } else {
        requestUrl = requestUrl;
      }
      if (searchReserveStatus.length > 0) {
        requestUrl = requestUrl + `&reservestatus=${searchReserveStatus}`;
      } else {
        requestUrl = requestUrl;
      }
      if (isConsecutiveReserveFilter) {
        requestUrl =
          requestUrl + `&isconsecutivereserve=${isConsecutiveReserveFilter}`;
      } else {
        requestUrl = requestUrl;
      }
      if (unsentMailReserveFilter) {
        requestUrl = requestUrl + `&unsentmail=${unsentMailReserveFilter}`;
      } else {
        requestUrl = requestUrl;
      }
      console.log("reserve list url =====>>>", requestUrl);
      const response = await getData(requestUrl);
      console.log("reserve list response ========>>>", response);
      if (response.status) {
        setIsReserveListLoading(false);
        setReservationList(response.data);
        setResponseReceived(true);
        // setResult(process(response.data, initialDataState));
        dataState
          ? setResult(process(response.data, dataState))
          : setResult(process(response.data, initialDataState));
        setRecords(response.data.length);
        // resetOtherComponentStates();
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const onDataStateChange = (event) => {
    setDataState(event.dataState);
    setResult(process(reservationList, event.dataState));
  };

  /* function to send mail to beneficiary */
  const mailToBeneficiary = async (dataItem) => {
    let recieversMailsArray = [];
    if (dataItem.beneficiaries && dataItem.beneficiaries.length > 0) {
      dataItem.beneficiaries.map((userData) => {
        recieversMailsArray.push(userData.email);
      });
    }
    if (dataItem.responsibles && dataItem.responsibles.length > 0) {
      dataItem.responsibles.map((userData) => {
        recieversMailsArray.push(userData.email);
      });
    }
    if (recieversMailsArray.length > 0) {
      const mailData = {
        reserve: dataItem._id,
        template: "reserve-info-beneficiary",
        receivermailarray: recieversMailsArray,
      };
      try {
        let requestUrl =
          url.API_BASEURL +
          url.API_SEND_MESSAGE_BY_TEMPLATE +
          `?token=${token}`;
        const response = await postData(requestUrl, mailData);
        if (response.status) {
          console.log(response);
          setNotificationString("Mail sent to beneficiary");
          setSuccess(true);
        }
      } catch (error) {
        console.error(error.message);
      }
    } else {
      setNotificationString(
        "No beneficiary/responsible associated with the reserve"
      );
      setSuccess(true);
    }
  };

  /* function to send mail to observer */
  const mailToObserver = async (dataItem) => {
    let observerMailsArray = [];
    if (dataItem.observers) {
      dataItem.observers.map((observerData) => {
        observerMailsArray.push(observerData.email);
      });
      const mailData = {
        reserve: dataItem._id,
        template: "reserve-info-beneficiary",
        receivermailarray: observerMailsArray,
      };
      try {
        let requestUrl =
          url.API_BASEURL +
          url.API_SEND_MESSAGE_BY_TEMPLATE +
          `?token=${token}`;
        const response = await postData(requestUrl, mailData);
        if (response.status) {
          setNotificationString("Mail sent to observers");
          setSuccess(true);
        }
      } catch (error) {
        console.error(error.message);
      }
    } else {
      setNotificationString("No observer associated with the reserve");
      setSuccess(true);
    }
  };

  //function for change reconfirmation
  const reConfirmHandler = async (e) => {
    const booleanToStringValue = e.target.checked.toString();
    let reserveData = {
      reconfirmation: booleanToStringValue,
    };
    // console.log("Reconfirm data------>", reserveData);
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_UPDATE_RESERVE_EVENT +
        `/${e.target.value}` +
        `?token=${token}`;
      const response = await putData(requestUrl, reserveData);
      // console.log("response in update reconfirmation----->", response);
      if (response.status) {
        setReloadReservationList(true);
        setSuccess(true);
        setNotificationString(
          t("Reconfirmation has been updated successfully")
        );
      }
    } catch (error) {
      console.error(error.message);
      setError(true);
      setNotificationString(t("Something went wrong, please try again later"));
    }
  };

  // Primary Block
  const primaryBlockHtml = (props) => {
    //check if logged in user is eductor
    const userIdEductor = props.dataItem?.educators?.find(
      (educator) => educator._id.toString() === userInfo._id.toString()
    );
    //function for download material
    /* function to download file */
    const materialDownloadHandler = async () => {
      const uploads = props.dataItem?.activitycode?.uploads ?? [];
      const beneficiaryUploads =
        props.dataItem?.activitycode?.beneficiaryuploads ?? [];
      const mergedFiles = [...uploads, ...beneficiaryUploads];
      if (userInfo?.role?.name == "admin") {
        if (mergedFiles.length > 0) {
          for (const file of mergedFiles) {
            downloadFileHandler(file);
          }
        }
      } else if (userInfo?.role?.name == "educator") {
        if (uploads.length > 0) {
          for (const file of uploads) {
            downloadFileHandler(file);
          }
        }
      } else if (userInfo?.role?.name == "beneficiary") {
        if (beneficiaryUploads.length > 0) {
          for (const file of beneficiaryUploads) {
            downloadFileHandler(file);
          }
        }
      } else {
      }
    };
    return (
      <td className="kendoCustomColumn overflow-visible">
        <div className="d-flex align-items-center gap-2">
          {/* ------- custom reserve id start ------- */}
          <p className="me-2 mb-0">
            {props.dataItem.reserveid}{" "}
            {props.dataItem.isconsecutivereserve && (
              <span
                className="material-symbols-outlined icon-md text-secondary"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Consecutive reserve"
              >
                copyright
              </span>
            )}
          </p>
          {/* ------- custom reserve id end ------- */}
          <span
            className={`d-block material-symbols-outlined icon-md me-2 ms-auto ${
              props.dataItem.zipcode ? "text-green" : "text-danger"
            }`}
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="location zipcode"
          >
            location_on
          </span>
          {/* --------- reserve history with icon start ---------- */}
          <Link
            to="#historyReservePopup"
            className="text-black ms-auto"
            data-bs-toggle="offcanvas"
            role="button"
            aria-controls="historyReservePopup"
            onClick={() => {
              setReserveIdNumber(props.dataItem.reserveid);
            }}
          >
            <span
              className="d-block material-symbols-outlined icon-md"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="History of modification"
            >
              history
            </span>
          </Link>
          {/* --------- reserve history with icon end ---------- */}
          {/* --------- edit reserve with icon start ---------- */}
          {userInfo?.role?.name == "admin" ||
          userInfo?.role?.name == "manager" ? (
            <Link
              to="#saveReservationPopup"
              data-bs-toggle="offcanvas"
              className="text-black ms-2"
              role="button"
              aria-controls="saveReservationPopup"
              onClick={() => {
                let editData = {
                  _id: props.dataItem?._id,
                  reserveid: props.dataItem?.reserveid,
                };
                setEditReserveData(editData);
              }}
            >
              <span
                className="d-block material-symbols-outlined icon-md"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="update reserve data"
              >
                edit
              </span>
            </Link>
          ) : null}
          {/* --------- edit reserve with icon end ---------- */}
          {/* -------- dropdown section start -------- */}
          {userIdEductor ||
          userInfo?.role?.name == "admin" ||
          userInfo?.role?.name == "manager" ||
          userInfo?.role?.name == "observer" ||
          userInfo?.role?.name == "client" ||
          userInfo?.role?.name == "participant" ||
          userInfo?.role?.name == "user" ||
          userInfo?.role?.name == "beneficiary" ? (
            <div className="dropdown cell-dropdown ms-auto mt-2">
              {/* ------ more vert icon start ------ */}
              <button
                className=" bg-transparent p-0 border-0"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span className="d-block material-symbols-outlined">
                  more_vert
                </span>
              </button>
              {/* ------ more vert icon end ------ */}
              {/* ------ dropdown list start ------ */}
              {userInfo?.role?.name == "admin" ||
              userInfo?.role?.name == "manager" ? (
                <ul className="dropdown-menu border-0 shadow-lg">
                  {/* ----- download material start ------ */}
                  <li>
                    <Link
                      className="dropdown-item"
                      to="#"
                      onClick={materialDownloadHandler}
                    >
                      {t("Download Materials")}
                    </Link>
                  </li>
                  {/* ----- download material end ------ */}
                  {/* ----- Send Activity to beneficiary ------ */}
                  <li>
                    <Link
                      className="dropdown-item"
                      to="#"
                      onClick={() => {
                        mailToBeneficiary(props.dataItem);
                      }}
                    >
                      {t("Send Activity to Responsible")}
                    </Link>
                  </li>
                  {/* ----- Send Activity to observer ------ */}
                  <li>
                    <Link
                      className="dropdown-item"
                      to="#"
                      onClick={() => {
                        mailToObserver(props.dataItem);
                      }}
                    >
                      {t("Send Activity to observer")}
                    </Link>
                  </li>
                  {/* ----- view feedback start ------ */}
                  <li>
                    <Link
                      className="dropdown-item"
                      to="#allTotalReserveSurveyPopup"
                      data-bs-toggle="offcanvas"
                      role="button"
                      aria-controls="saveReservationPopup"
                      onClick={() =>
                        setCustomReserveId(props.dataItem.reserveid)
                      }
                    >
                      {t("View Feedback")}
                    </Link>
                  </li>
                  {/* ----- view feedback end ------ */}
                  {/* ----- Copy reserve start ------ */}
                  <li>
                    <Link
                      className="dropdown-item"
                      to="#saveReservationPopup"
                      data-bs-toggle="offcanvas"
                      role="button"
                      aria-controls="saveReservationPopup"
                      onClick={() => {
                        setCopyReserveData(props.dataItem.reserveid);
                      }}
                    >
                      {t("Copy reserve")}
                    </Link>
                  </li>
                  {/* ----- Copy reserve end ------ */}
                  {/* ----- isconsecutive block start ------ */}
                  {props.dataItem.isconsecutivereserve ? (
                    <li>
                      <Link
                        className="dropdown-item"
                        to="#saveReservationPopup"
                        data-bs-toggle="offcanvas"
                        role="button"
                        aria-controls="saveReservationPopup"
                        onClick={() => {
                          setaddMoreConsecutiveReserveData(
                            props.dataItem.lastconsecutivereserve
                          );
                        }}
                      >
                        {t("Add more consecutive event")}
                      </Link>
                    </li>
                  ) : null}
                  {/* ----- isconsecutive block end ------ */}
                </ul>
              ) : (
                <ul className="dropdown-menu border-0 shadow-lg">
                  {/* ----- download material start ------ */}
                  <li>
                    <Link
                      className="dropdown-item"
                      to="#"
                      onClick={materialDownloadHandler}
                    >
                      {t("Download Materials")}
                    </Link>
                  </li>
                  {/* ----- download material end ------ */}
                </ul>
              )}
              {/* ------ dropdown list end ------ */}
            </div>
          ) : (
            <div className="cell-dropdown ms-auto mt-2">
              <button
                className=" bg-transparent p-0 border-0"
                type="button"
                // data-bs-toggle="dropdown"
                // aria-expanded="false"
              >
                <span className="d-block material-symbols-outlined text-danger">
                  disabled_by_default
                </span>
              </button>
            </div>
          )}
          {/* -------- dropdown section end -------- */}
        </div>
      </td>
    );
  };

  // Primary Block
  const primaryBlockTemplateHtml = (props) => {
    //function for download material
    return (
      <td className="kendoCustomColumn overflow-visible">
        <div className="d-flex align-items-center gap-2">
          {/* ------- custom reserve id start ------- */}
          <p className="me-2 mb-0">
            {props.dataItem.reserveid} {props.dataItem.templatename}
          </p>
          {/* ------- custom reserve id end ------- */}
          <span
            className={`d-block material-symbols-outlined icon-md me-2 ms-auto ${
              props.dataItem.zipcode ? "text-green" : "text-danger"
            }`}
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="location zipcode"
          >
            location_on
          </span>
          {/* --------- edit reserve with icon start ---------- */}
          {userInfo?.role?.name == "admin" ||
          userInfo?.role?.name == "manager" ? (
            <Link
              to="#saveReservationPopup"
              data-bs-toggle="offcanvas"
              className="text-black ms-2"
              role="button"
              aria-controls="saveReservationPopup"
              onClick={() => {
                let editData = {
                  _id: props.dataItem?._id,
                  reserveid: props.dataItem?.reserveid,
                };
                setEditReserveData(editData);
              }}
            >
              <span
                className="d-block material-symbols-outlined icon-md"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="update reserve data"
              >
                edit
              </span>
            </Link>
          ) : null}
          {/* --------- edit reserve with icon end ---------- */}
        </div>
      </td>
    );
  };

  // Educator 1 Block
  const educatorOneblockHTML = (props) => {
    // Destructure the props to access dataItem
    const { dataItem } = props;
    //function for change eductor set data in context
    const changeEducatorHandler = () => {
      let reserveData = {};
      if (props.dataItem.educators.length > 0) {
        reserveData = {
          startdate: props.dataItem.startdate,
          starttime: props.dataItem.starttime,
          endtime: props.dataItem.endtime,
          shift: props.dataItem.shift,
          reserveId: props.dataItem._id,
          prevEducatore: props.dataItem.educators[0]._id,
          activitycodename: props.dataItem.activitycode
            ? props.dataItem.activitycode.code +
              " : " +
              props.dataItem.activitycode.name
            : " ",
          reserveid: props.dataItem.reserveid,
          activitycode: props.dataItem.activity.value,
        };
      } else {
        reserveData = {
          startdate: props.dataItem.startdate,
          starttime: props.dataItem.starttime,
          endtime: props.dataItem.endtime,
          shift: props.dataItem.shift,
          reserveId: props.dataItem._id,
          prevEducatore: null,
          activitycodename: props.dataItem.activitycode
            ? props.dataItem.activitycode.code +
              " : " +
              props.dataItem.activitycode.name
            : " ",
          reserveid: props.dataItem.reserveid,
          activitycode: props.dataItem.activity.value,
        };
      }
      setChangeReserveEducatorData(reserveData);
    };
    //function for set person survey data in context
    const surveyHandler = () => {
      if (props.dataItem.educators.length > 0) {
        const reserveEvent = props.dataItem._id;
        const surveyPerson = props.dataItem.educators[0];
        const reserveid = props.dataItem.reserveid;
        let reserveSurveyData = {
          reserveEvent,
          surveyPerson,
          reserveid,
        };
        setReservePersonData(reserveSurveyData);
      } else {
        setReservePersonData(null);
      }
    };
    //function for set survey data for feedback
    const clientFeedBackHandler = () => {
      if (props.dataItem.educators.length > 0) {
        const reserveEvent = props.dataItem._id;
        const surveyPerson = props.dataItem.educators[0];
        const reserveid = props.dataItem.reserveid;
        let reserveSurveyData = {
          reserveEvent,
          surveyPerson,
          reserveid,
        };
        setClientFeedbackEducatorData(reserveSurveyData);
      } else {
        setClientFeedbackEducatorData(null);
      }
    };
    //function for mail history
    const mailHistory = () => {
      if (props.dataItem.educators.length > 0) {
        const reserveEvent = props.dataItem._id;
        const reserveId = props.dataItem.reserveid;
        const mailpersonid = props.dataItem.educators[0]._id;
        const mailPersonEmail = props.dataItem.educators[0].email;
        let reserveMailData = {
          reserveEvent,
          reserveId,
          mailpersonid,
          mailPersonEmail,
        };
        setReservationEmailHistoryData(reserveMailData);
      } else {
        setReservationEmailHistoryData(null);
      }
    };
    // Check if the conditions are met
    const isAdminOrManagerOrEducator =
      userInfo?.role?.name === "admin" ||
      userInfo?.role?.name === "manager" ||
      userInfo._id === dataItem?.educator1?._id;
    // Common link props for educatorOneLink
    const commonLinkProps = {
      className: "text-dark",
    };
    // If the conditions are met, set the onClick function and the to attribute
    const educatorOneLinkProps = isAdminOrManagerOrEducator
      ? {
          ...commonLinkProps,
          to: "#email_history_popup",
          "data-bs-toggle": "offcanvas",
          onClick: mailHistory,
        }
      : {
          ...commonLinkProps,
          to: "#",
        };
    return (
      <td className="educator">
        {/* ----- educator name section start ------ */}
        <p className="name mb-1">
          <Link {...educatorOneLinkProps}>
            {props.dataItem?.educator1?.name}
          </Link>
        </p>
        {/* ----- educator name section end ------ */}
        {/* ----- educator icon section start ------ */}
        <div className="action d-flex gap-3 align-items-center">
          {props.dataItem.educator1 && (
            <p className="d-flex gap-1">
              {props.dataItem.educatoronemailtracker == "2" ? (
                <span className="d-block cursor-p material-symbols-outlined text-green icon-md">
                  mark_email_read
                </span>
              ) : (
                ""
              )}
              {props.dataItem.educatoronemailtracker == "1" ? (
                <span className="d-block cursor-p material-symbols-outlined text-grey icon-md">
                  mail
                </span>
              ) : (
                ""
              )}
              {props.dataItem.educatoronememberrsponsedate == "" ? null : (
                <span>{props.dataItem.educatoronememberrsponsedate}</span>
              )}
              <span
                className="d-block material-symbols-outlined icon-md icon-fill"
                style={{ color: props.dataItem.educatoronemailcolour }}
              >
                thumb_up
              </span>
            </p>
          )}
          <div className="d-flex gap-2 align-items-center">
            {/* ----- educator survey popup link start ------ */}
            {props.dataItem?.educator1 && (
              <div className="d-block">
                {isAdminOrManagerOrEducator ? (
                  <Link
                    to={
                      props.dataItem.educators.length > 0
                        ? "#allReserveSurveyPopup"
                        : "#"
                    }
                    role="button"
                    className="text-gray-700"
                    data-bs-toggle="offcanvas"
                    onClick={surveyHandler}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="All survey"
                      className="d-block material-symbols-outlined icon-md"
                    >
                      forum
                    </span>
                  </Link>
                ) : null}
              </div>
            )}
            {/* ----- educator survey popup link end ------ */}
            {/* ----- educator replace popup link start ------ */}
            {userInfo?.role?.name == "admin" ||
            userInfo?.role?.name == "manager" ? (
              <div className="d-block">
                {props.dataItem.educatorcount > 0 && (
                  <Link
                    to="#selectEducatorPopup"
                    data-bs-toggle="offcanvas"
                    role="button"
                    aria-controls="saveReservationPopup"
                    className="text-black"
                    onClick={changeEducatorHandler}
                  >
                    <span
                      className="d-block material-symbols-outlined icon-md"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Select educator"
                    >
                      {props.dataItem.educator1 ? "edit" : "add"}
                    </span>
                  </Link>
                )}
              </div>
            ) : null}
            {/* ----- educator replace popup link end ------ */}
            {/* ----- client to educator survey popup link start ------ */}
            {userInfo?.role?.name == "client" ||
            userInfo.role?.name == "educator" ||
            userInfo?.role?.name === "admin" ? (
              <div className="d-block">
                {props.dataItem.educator1 && (
                  <Link
                    to="#client_educator_reserve_survey_feedback_popup"
                    data-bs-toggle="offcanvas"
                    role="button"
                    aria-controls="saveReservationPopup"
                    className="text-black"
                    onClick={clientFeedBackHandler}
                  >
                    <span
                      className="d-block material-symbols-outlined icon-md"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Select educator"
                    >
                      try
                    </span>
                  </Link>
                )}
              </div>
            ) : null}
            {/* ----- client to educator survey popup link end ------ */}
          </div>
        </div>
        {/* ----- educator icon section end ------ */}
      </td>
    );
  };

  // Educator 2 Block
  const educatorTwoblockHTML = (props) => {
    //function for change eductor set data in context
    const changeEducatorHandler = () => {
      let reserveData = {};
      if (props.dataItem.educators.length > 1) {
        reserveData = {
          startdate: props.dataItem.startdate,
          starttime: props.dataItem.starttime,
          endtime: props.dataItem.endtime,
          reserveId: props.dataItem._id,
          prevEducatore: props.dataItem.educators[1]._id,
          activitycodename: props.dataItem.activitycode
            ? props.dataItem.activitycode.code +
              " : " +
              props.dataItem.activitycode.name
            : " ",
          reserveid: props.dataItem.reserveid,
          activitycode: props.dataItem.activity.value,
        };
      } else {
        reserveData = {
          startdate: props.dataItem.startdate,
          starttime: props.dataItem.starttime,
          endtime: props.dataItem.endtime,
          reserveId: props.dataItem._id,
          prevEducatore: null,
          activitycodename: props.dataItem.activitycode
            ? props.dataItem.activitycode.code +
              " : " +
              props.dataItem.activitycode.name
            : " ",
          reserveid: props.dataItem.reserveid,
          activitycode: props.dataItem.activity.value,
        };
      }
      setChangeReserveEducatorData(reserveData);
    };
    //function for set person survey data in context
    const surveyHandler = () => {
      if (props.dataItem.educators.length > 1) {
        const reserveEvent = props.dataItem._id;
        const surveyPerson = props.dataItem.educators[1];
        const reserveid = props.dataItem.reserveid;
        let reserveSurveyData = {
          reserveEvent,
          surveyPerson,
          reserveid,
        };
        setReservePersonData(reserveSurveyData);
      } else {
        setReservePersonData(null);
      }
    };
    //function for set survey data for feedback
    const clientFeedBackHandler = () => {
      if (props.dataItem.educators.length > 0) {
        const reserveEvent = props.dataItem._id;
        const surveyPerson = props.dataItem.educators[1];
        const reserveid = props.dataItem.reserveid;
        let reserveSurveyData = {
          reserveEvent,
          surveyPerson,
          reserveid,
        };
        setClientFeedbackEducatorData(reserveSurveyData);
      } else {
        setClientFeedbackEducatorData(null);
      }
    };
    //function for mail history
    const mailHistory = () => {
      if (props.dataItem.educators.length > 1) {
        const reserveEvent = props.dataItem._id;
        const reserveId = props.dataItem.reserveid;
        const mailpersonid = props.dataItem.educators[1]._id;
        const mailPersonEmail = props.dataItem.educators[1].email;
        let reserveMailData = {
          reserveEvent,
          reserveId,
          mailpersonid,
          mailPersonEmail,
        };
        setReservationEmailHistoryData(reserveMailData);
      } else {
        setReservationEmailHistoryData(null);
      }
    };
    // Check if the conditions are met
    const isAdminOrManagerOrEducator =
      userInfo?.role?.name === "admin" ||
      userInfo?.role?.name === "manager" ||
      userInfo._id === props.dataItem?.educator2?._id;
    // Common link props for educatorOneLink
    const commonLinkProps = {
      className: "text-dark",
    };
    // If the conditions are met, set the onClick function and the to attribute
    const educatorTwoLinkProps = isAdminOrManagerOrEducator
      ? {
          ...commonLinkProps,
          to: "#email_history_popup",
          "data-bs-toggle": "offcanvas",
          onClick: mailHistory,
        }
      : {
          ...commonLinkProps,
          to: "#",
        };
    return (
      <td className="educator2">
        <p className="name mb-1">
          <Link {...educatorTwoLinkProps}>
            {props.dataItem?.educator2?.name}
          </Link>
        </p>
        {/* ----- educator icon section start ------ */}
        <div className="action d-flex gap-3 align-items-center">
          {props.dataItem.educator2 && (
            <p className="d-flex gap-1">
              {props.dataItem.educatortwomailtracker == "2" ? (
                <span className="d-block cursor-p material-symbols-outlined text-green icon-md">
                  mark_email_read
                </span>
              ) : (
                ""
              )}
              {props.dataItem.educatortwomailtracker == "1" ? (
                <span className="d-block cursor-p material-symbols-outlined text-grey icon-md">
                  mail
                </span>
              ) : (
                ""
              )}
              {props.dataItem.educatortwomemberrsponsedate == "" ? null : (
                <span>{props.dataItem.educatortwomemberrsponsedate}</span>
              )}
              <span
                className="d-block material-symbols-outlined icon-md icon-fill"
                style={{ color: props.dataItem.educatortwomailcolour }}
              >
                thumb_up
              </span>
            </p>
          )}
          <div className="action d-flex gap-2 align-items-center">
            {/* ----- educator survey popup link start ------ */}
            {props.dataItem.educator2 ? (
              <div className="d-block">
                {isAdminOrManagerOrEducator ? (
                  <Link
                    onClick={surveyHandler}
                    data-bs-toggle="offcanvas"
                    to={
                      props.dataItem.educators.length > 1
                        ? "#allReserveSurveyPopup"
                        : "#"
                    }
                    role="button"
                    className="text-green"
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="All survey"
                      className="d-block material-symbols-outlined icon-md"
                    >
                      forum
                    </span>
                  </Link>
                ) : null}
              </div>
            ) : null}
            {/* ----- educator replace popup link start ------ */}
            {userInfo?.role?.name == "admin" ||
            userInfo?.role?.name == "manager" ? (
              <div className="d-block">
                {props.dataItem.educatorcount > 1 && (
                  <Link
                    to="#selectEducatorPopup"
                    data-bs-toggle="offcanvas"
                    role="button"
                    aria-controls="saveReservationPopup"
                    className="text-black"
                    onClick={changeEducatorHandler}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Select educator"
                      className="d-block material-symbols-outlined icon-md"
                    >
                      {props.dataItem.educator2 ? "edit" : "add"}
                    </span>
                  </Link>
                )}
              </div>
            ) : null}
            {/* ----- client to educator survey popup link start ------ */}
            {userInfo?.role?.name == "client" ||
            userInfo.role?.name == "educator" ||
            userInfo?.role?.name === "admin" ? (
              <div className="d-block">
                {props.dataItem.educator2 && (
                  <Link
                    to="#client_educator_reserve_survey_feedback_popup"
                    data-bs-toggle="offcanvas"
                    role="button"
                    aria-controls="saveReservationPopup"
                    className="text-black"
                    onClick={clientFeedBackHandler}
                  >
                    <span
                      className="d-block material-symbols-outlined icon-md"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Select educator"
                    >
                      try
                    </span>
                  </Link>
                )}
              </div>
            ) : null}
            {/* ----- client to educator survey popup link end ------ */}
          </div>
        </div>
      </td>
    );
  };

  // Client Block
  const clientBlockHTML = (props) => {
    //function for set person survey data in context
    const surveyHandler = () => {
      if (props.dataItem.clients.length > 0) {
        const reserveEvent = props.dataItem._id;
        const surveyPerson = props.dataItem.clients[0];
        const reserveid = props.dataItem.reserveid;
        let reserveSurveyData = {
          reserveEvent,
          surveyPerson,
          reserveid,
        };
        setReservePersonData(reserveSurveyData);
      } else {
        setReservePersonData(null);
      }
    };
    //function for mail history
    const mailHistory = () => {
      if (props.dataItem.clients.length > 0) {
        const reserveEvent = props.dataItem._id;
        const reserveId = props.dataItem.reserveid;
        const mailpersonid = props.dataItem.clients[0]._id;
        const mailPersonEmail = props.dataItem.clients[0].email;
        let reserveMailData = {
          reserveEvent,
          reserveId,
          mailpersonid,
          mailPersonEmail,
        };
        setReservationEmailHistoryData(reserveMailData);
      } else {
        setReservationEmailHistoryData(null);
      }
    };
    // Check if the conditions are met
    const isAdminOrManagerOrClient =
      userInfo?.role?.name === "admin" ||
      userInfo?.role?.name === "manager" ||
      userInfo._id === props?.dataItem?.client?._id;
    // Common link props for clientLink
    const commonLinkProps = {
      className: "text-dark",
    };
    // Add additional link props if the conditions are met
    const clientLinkProps = isAdminOrManagerOrClient
      ? {
          ...commonLinkProps,
          to: "#email_history_popup",
          "data-bs-toggle": "offcanvas",
          onClick: mailHistory,
        }
      : {
          ...commonLinkProps,
          to: "#",
        };
    return (
      <td className="clients">
        <p className="name mb-1">
          <Link {...clientLinkProps}>{props.dataItem?.client?.name}</Link>
        </p>
        {props.dataItem.client && (
          <div className="d-block">
            {isAdminOrManagerOrClient ? (
              <Link
                onClick={surveyHandler}
                to="#allReserveSurveyPopup"
                data-bs-toggle="offcanvas"
                className="text-gray-700"
              >
                <span
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="All Survey"
                  className="d-block material-symbols-outlined icon-md"
                >
                  forum
                </span>
              </Link>
            ) : null}
          </div>
        )}
      </td>
    );
  };

  // Observer Block
  const observerHTML = (props) => {
    //function for set person survey data in context
    const surveyHandler = () => {
      if (props.dataItem.observers.length > 0) {
        const reserveEvent = props.dataItem._id;
        const surveyPerson = props.dataItem.observers[0];
        const reserveid = props.dataItem.reserveid;
        let reserveSurveyData = {
          reserveEvent,
          surveyPerson,
          reserveid,
        };
        setReservePersonData(reserveSurveyData);
      } else {
        setReservePersonData(null);
      }
    };
    //function for mail history
    const mailHistory = () => {
      if (props.dataItem.observers.length > 0) {
        const reserveEvent = props.dataItem._id;
        const reserveId = props.dataItem.reserveid;
        const mailpersonid = props.dataItem.observers[0]._id;
        const mailPersonEmail = props.dataItem.observers[0].email;
        let reserveMailData = {
          reserveEvent,
          reserveId,
          mailpersonid,
          mailPersonEmail,
        };
        setReservationEmailHistoryData(reserveMailData);
      } else {
        setReservationEmailHistoryData(null);
      }
    };
    // Check if the conditions are met
    const isAdminOrManagerOrObserver =
      userInfo?.role?.name === "admin" ||
      userInfo?.role?.name === "manager" ||
      userInfo._id === props?.dataItem?.observer?._id;
    // Common link props for observer
    const commonLinkProps = {
      className: "text-dark",
    };
    // Add additional link props if the conditions are met
    const observerLinkProps = isAdminOrManagerOrObserver
      ? {
          ...commonLinkProps,
          to: "#email_history_popup",
          "data-bs-toggle": "offcanvas",
          onClick: mailHistory,
        }
      : {
          ...commonLinkProps,
          to: "#",
        };
    return (
      <td className="observer">
        <p className="name mb-1">
          <Link {...observerLinkProps}>{props.dataItem.observer?.name}</Link>
        </p>
        {props.dataItem.observer && (
          <div className="d-block">
            {isAdminOrManagerOrObserver ? (
              <Link
                onClick={surveyHandler}
                to="#allReserveSurveyPopup"
                data-bs-toggle="offcanvas"
                className="text-gray-700"
              >
                <span
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="All Survey"
                  className="d-block material-symbols-outlined icon-md"
                >
                  forum
                </span>
              </Link>
            ) : null}
          </div>
        )}
      </td>
    );
  };

  // Benificiary Block
  const benificiaryHTML = (props) => {
    //function for set person survey data in context
    const surveyHandler = () => {
      if (props.dataItem.beneficiaries.length > 0) {
        const reserveEvent = props.dataItem._id;
        const surveyPerson = props.dataItem.beneficiaries[0];
        const reserveid = props.dataItem.reserveid;
        let reserveSurveyData = {
          reserveEvent,
          surveyPerson,
          reserveid,
        };
        setReservePersonData(reserveSurveyData);
      } else {
        setReservePersonData(null);
      }
    };
    //function for mail history
    const mailHistory = () => {
      if (props.dataItem.beneficiaries.length > 0) {
        const reserveEvent = props.dataItem._id;
        const reserveId = props.dataItem.reserveid;
        const mailpersonid = props.dataItem.beneficiaries[0]._id;
        const mailPersonEmail = props.dataItem.beneficiaries[0].email;
        let reserveMailData = {
          reserveEvent,
          reserveId,
          mailpersonid,
          mailPersonEmail,
        };
        setReservationEmailHistoryData(reserveMailData);
      } else {
        setReservationEmailHistoryData(null);
      }
    };
    // Check if the conditions are met
    const isAdminOrManagerOrBenificiary =
      userInfo?.role?.name === "admin" ||
      userInfo?.role?.name === "manager" ||
      userInfo._id === props?.dataItem?.beneficiary?._id;
    // Common link props for observer
    const commonLinkProps = {
      className: "text-dark",
    };
    // Add additional link props if the conditions are met
    const benificiaryLinkProps = isAdminOrManagerOrBenificiary
      ? {
          ...commonLinkProps,
          to: "#email_history_popup",
          "data-bs-toggle": "offcanvas",
          onClick: mailHistory,
        }
      : {
          ...commonLinkProps,
          to: "#",
        };
    return (
      <td className="beneficiary">
        <p className="name mb-1">
          <Link {...benificiaryLinkProps}>
            {props.dataItem.beneficiary?.name}
          </Link>
        </p>
        {/* ----- educator icon section start ------ */}
        <div className="action d-flex gap-3 align-items-center">
          {props.dataItem.beneficiary && (
            <p className="d-flex gap-1">
              {props.dataItem.beneficiarymailtracker == "2" ? (
                <span className="d-block cursor-p material-symbols-outlined text-green icon-md">
                  mark_email_read
                </span>
              ) : (
                ""
              )}
              {props.dataItem.beneficiarymailtracker == "1" ? (
                <span className="d-block cursor-p material-symbols-outlined text-grey icon-md">
                  mail
                </span>
              ) : (
                ""
              )}
              {props.dataItem.beneficiarymemberresponsedate == "" ? null : (
                <span>{props.dataItem.beneficiarymemberresponsedate}</span>
              )}
              <span
                className="d-block material-symbols-outlined icon-md icon-fill"
                style={{ color: props.dataItem.beneficiarymailcolour }}
              >
                thumb_up
              </span>
            </p>
          )}
          <div className="d-flex gap-2 align-items-center">
            {/* ----- educator survey popup link start ------ */}
            {props.dataItem.beneficiary && (
              <div className="d-block">
                {isAdminOrManagerOrBenificiary ? (
                  <Link
                    onClick={surveyHandler}
                    to="#allReserveSurveyPopup"
                    data-bs-toggle="offcanvas"
                    className="text-gray-700"
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="All Survey"
                      className="d-block material-symbols-outlined icon-md"
                    >
                      forum
                    </span>
                  </Link>
                ) : null}
              </div>
            )}
          </div>
        </div>
      </td>
    );
  };

  //responsible Block
  const responsibleHTML = (props) => {
    //function for set person survey data in context
    const surveyHandler = () => {
      if (
        props.dataItem?.responsibles &&
        props.dataItem?.responsibles?.length > 0
      ) {
        const reserveEvent = props.dataItem._id;
        const surveyPerson = props.dataItem?.responsibles[0];
        const reserveid = props.dataItem?.reserveid;
        let reserveSurveyData = {
          reserveEvent,
          surveyPerson,
          reserveid,
        };
        setReservePersonData(reserveSurveyData);
      } else {
        setReservePersonData(null);
      }
    };
    //function for mail history
    const mailHistory = () => {
      if (
        props.dataItem.responsibles &&
        props.dataItem.responsibles.length > 0
      ) {
        const reserveEvent = props.dataItem._id;
        const reserveId = props.dataItem?.reserveid;
        const mailpersonid = props.dataItem?.responsibles[0]._id;
        const mailPersonEmail = props.dataItem?.responsibles[0].email;
        let reserveMailData = {
          reserveEvent,
          reserveId,
          mailpersonid,
          mailPersonEmail,
        };
        setReservationEmailHistoryData(reserveMailData);
      } else {
        setReservationEmailHistoryData(null);
      }
    };
    // Check if the conditions are met
    const isAdminOrManagerOrResponsible =
      userInfo?.role?.name === "admin" ||
      userInfo?.role?.name === "manager" ||
      userInfo._id === props?.dataItem?.responsible?._id;
    // Common link props for observer
    const commonLinkProps = {
      className: "text-dark",
    };
    // Add additional link props if the conditions are met
    const responsibleLinkProps = isAdminOrManagerOrResponsible
      ? {
          ...commonLinkProps,
          to: "#email_history_popup",
          "data-bs-toggle": "offcanvas",
          onClick: mailHistory,
        }
      : {
          ...commonLinkProps,
          to: "#",
        };
    return (
      <td className="beneficiary">
        <p className="name mb-1">
          <Link {...responsibleLinkProps}>
            {props.dataItem.responsible?.name}
          </Link>
        </p>
        {/* ----- educator icon section start ------ */}
        <div className="action d-flex gap-3 align-items-center">
          {props.dataItem?.responsible && (
            <p className="d-flex gap-1">
              {props.dataItem.responsiblemailtracker == "2" ? (
                <span className="d-block cursor-p material-symbols-outlined text-green icon-md">
                  mark_email_read
                </span>
              ) : (
                ""
              )}
              {props.dataItem.responsiblemailtracker == "1" ? (
                <span className="d-block cursor-p material-symbols-outlined text-grey icon-md">
                  mail
                </span>
              ) : (
                ""
              )}
              {props.dataItem.responsiblememberresponsedate == "" ? null : (
                <span>{props.dataItem.responsiblememberresponsedate}</span>
              )}
              <span
                className="d-block material-symbols-outlined icon-md icon-fill"
                style={{ color: props.dataItem.responsiblemailcolour }}
              >
                thumb_up
              </span>
            </p>
          )}
          <div className="d-flex gap-2 align-items-center">
            {/* ----- educator survey popup link start ------ */}
            {props.dataItem.responsible && (
              <div className="d-block">
                {isAdminOrManagerOrResponsible ? (
                  <Link
                    onClick={surveyHandler}
                    to="#allReserveSurveyPopup"
                    data-bs-toggle="offcanvas"
                    className="text-gray-700"
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="All Survey"
                      className="d-block material-symbols-outlined icon-md"
                    >
                      forum
                    </span>
                  </Link>
                ) : null}
              </div>
            )}
          </div>
        </div>
      </td>
    );
  };

  // Location Block
  const locationHTML = (props) => {
    return (
      <td className="location">
        <p className="d-flex gap-2 align-items-center text-nowrap">
          <span
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={props.dataItem?.location ?? ""}
            className="d-block material-symbols-outlined icon-md text-black"
          >
            location_on
          </span>
          <span className="d-block">{props.dataItem?.location ?? ""}</span>
        </p>
      </td>
    );
  };

  // Status Block
  const statusHTML = (props) => {
    return (
      <td className="status">
        <p className="d-flex gap-2 align-items-center">
          <span className="d-block">
            {t(props.dataItem?.reservestatus ?? "")}
          </span>
          <span
            className={`d-block material-symbols-outlined icon-md ${
              props.dataItem?.reservestatus === "Confirmed"
                ? "text-green"
                : "text-danger"
            }`}
          >
            {props.dataItem?.reservestatus === "Confirmed"
              ? "check_circle"
              : props.dataItem.reservestatus === "Pending Confirmation"
              ? "pending"
              : "clear"}
          </span>
        </p>
      </td>
    );
  };

  // check the selected count and show the count in top ------------------------
  const handelSelectedCount = () => {
    let checkboxes = document.querySelectorAll(
      'input[name="selectReserveRecord"]'
    );
    let totalSelected = 0;
    let filteredData = [];
    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        totalSelected++;
        const filteredUser = reservationList.find(
          (user) => user._id === checkbox.value
        );
        filteredUser && filteredData.push(filteredUser);
      }
    });
    // Update the content of the block
    checkedRecordCount(totalSelected);
    return filteredData;
  };

  // mark all checkbox as selected ----------------
  const selectAllRecords = (e) => {
    document
      .querySelectorAll('.k-master-row input[name="selectReserveRecord"]')
      .forEach((checkbox) => {
        checkbox.checked = e.target.checked;
      });
    handelSelectedCount();
  };

  // blocks to replace the Grid HTML ==============================================
  const SelectRecordHeader = () => {
    return (
      <div className="d-flex align-items-center">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="selectAllMain"
            onChange={selectAllRecords}
          />
        </div>
      </div>
    );
  };

  /* Select single row */
  const SelectRecordRow = (props) => {
    return (
      <td>
        <div className="form-check">
          <input
            className="form-check-input "
            type="checkbox"
            value={props.dataItem?._id}
            name="selectReserveRecord"
            filtername="selectedrecord"
            data-reserveid={props.dataItem?.reserveid}
            data-activityname={props.dataItem?.activitycode?.name}
            data-date={props.dataItem?.startdate}
            data-start={props.dataItem?.starttime}
            data-end={props.dataItem?.endtime}
            data-benficiaryname={props.dataItem?.beneficiary?.name}
            data-benficiaryid={props.dataItem?.beneficiary?._id}
            data-grade={props.dataItem?.grade}
            data-noofeducators={props.dataItem?.educatorcount}
            data-noofparticipants={props.dataItem?.realparticipantcount}
            data-location={props.dataItem?.location}
            data-town={props.dataItem?.town}
            data-clientid={props.dataItem.client?._id}
            data-clientname={props.dataItem.client?.name}
            data-responsiblename={props.dataItem.responsible?.name}
            data-isconsecutivereserve={props.dataItem?.isconsecutivereserve}
            onClick={handelSelectedCount}
          />
        </div>
      </td>
    );
  };

  /* custom comments html */
  const commentHtml = ({ dataItem }) => {
    return (
      <td className="activity">
        <span
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title={dataItem?.comments ?? ""}
        >
          {dataItem && dataItem.comments
            ? dataItem?.comments.length > 20
              ? dataItem?.comments.slice(0, 20) + "..."
              : dataItem.comments
            : ""}
        </span>
      </td>
    );
  };

  //custom internal comments html
  const insternalCommentsHtml = ({ dataItem }) => {
    return (
      <td className="activity">
        <span
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title={dataItem?.internalcomment ?? ""}
        >
          {dataItem && dataItem.internalcomment
            ? dataItem?.internalcomment?.length > 20
              ? dataItem?.internalcomment.slice(0, 20) + "..."
              : dataItem?.internalcomment
            : ""}
        </span>
      </td>
    );
  };

  //salesorder html
  const salesOrderHtml = ({ dataItem }) => {
    return (
      <td>
        {dataItem?.salesorderids && (
          <p>
            {dataItem?.salesorderids.map((item, index) => {
              return (
                <span key={index}>
                  {item?.invoiceid}
                  <br />
                </span>
              );
            })}
          </p>
        )}
      </td>
    );
  };

  //custom reconfirm html
  const reConfirmHtml = ({ dataItem }) => {
    return (
      <td>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            value={dataItem._id}
            defaultChecked={dataItem?.reconfirmation}
            name="selectReserveReconfirmation"
            onClick={(e) => {
              reConfirmHandler(e);
            }}
          />
        </div>
      </td>
    );
  };

  //date html
  const reserveDateBlockHTML = ({ dataItem }) => {
    return (
      <td>
        <p>{dataItem.reservedate}</p>
      </td>
    );
  };

  //function for reset other component states
  const resetOtherComponentStates = () => {
    // setColumns([]);
    setSearchReserveDate("");
    setSearchReserveIdList([]);
    setSearchReserveActivity([]);
    setSearchReserveLabels([]);
    setSearchReserveBenificiaries([]);
    setSearchReserveClients([]);
    setSearchReserveEducators([]);
    setSearchReserveStatus([]);
    setisConsecutiveReserveFilter(false);
    setUnsentMailReserveFilter(false);
  };

  /* hook to reload list */
  useEffect(() => {
    if (reloadReservationList) {
      // setDataState(null);
      getAllReservationList();
      setReloadReservationList(false);
    }
  }, [reloadReservationList]);
  /* ---- hook to reload list on refresh ----- */
  useEffect(() => {
    if (reloadRefresh) {
      // setDataState(null);
      getAllReservationList();
      setReloadRefresh(false);
    }
  }, [reloadRefresh]);
  // useEffect(() => {
  //   if (responseReceived === false) {
  //     // console.log("Get function called");
  //     getAllReservationList();
  //   }
  // }, [responseReceived]);
  /*------ use effect for search -------*/
  useEffect(() => {
    if (
      searchReserveDate != "" ||
      searchReserveIdList.length > 0 ||
      searchReserveAmbIdList.length > 0 ||
      searchReserveActivity.length > 0 ||
      searchReserveLabels.length > 0 ||
      searchReserveBenificiaries.length > 0 ||
      searchReserveClients.length > 0 ||
      searchReserveExcludeClients.length > 0 ||
      searchReserveEducators.length > 0 ||
      searchReserveStatus.length > 0 ||
      isConsecutiveReserveFilter ||
      unsentMailReserveFilter
    ) {
      getAllReservationList();
    }
  }, [
    searchReserveDate,
    searchReserveIdList,
    searchReserveAmbIdList,
    searchReserveActivity,
    searchReserveLabels,
    searchReserveBenificiaries,
    searchReserveClients,
    searchReserveExcludeClients,
    searchReserveEducators,
    searchReserveStatus,
    isConsecutiveReserveFilter,
    unsentMailReserveFilter,
  ]);
  /* hook to hit  exportExcel() function*/
  useEffect(() => {
    downloadExcel && exportExcel();
  }, [downloadExcel]);
  useEffect(() => {
    if (columns.length > 0) {
      // console.log("filter columns in reservation -------->", columns);
      setSearchContainerClass("rearrangeColumn");
    }
  }, [columns]);

  useEffect(() => {
    filterData();
  }, [search]);

  useEffect(() => {
    if (result) {
      setRecords(result.data.length);
    }
  }, [result]);

  return (
    <div className={`table-responsive ${searchContainerClass}`}>
      {/* ============ download xls start ========== */}
      {columns.length > 0 ? (
        <ExcelExport
          data={
            handelSelectedCount().length > 0
              ? handelSelectedCount()
              : reservationList
          }
          fileName="Reservation List.xlsx"
          ref={_exporter}
        >
          <ExcelExportColumn field="reserveid" title={t("ID")} />
          <ExcelExportColumn
            field="isconsecutivestring"
            title={t("Is Consecutive")}
          />
          {columns.map((column) => {
            if (column === "Shift") {
              return (
                <ExcelExportColumn
                  key={column}
                  field="shiftlabel"
                  title={t("Shift")}
                />
              );
            }
            if (column == "AMB ID") {
              return (
                <ExcelExportColumn
                  key={column}
                  field="ambreservationid"
                  title={t("AMB Id")}
                />
              );
            }
            if (column == "Date") {
              return (
                <ExcelExportColumn
                  key={column}
                  field="reservedate"
                  title={t("Date")}
                />
              );
            }
            if (column == "Day") {
              return (
                <ExcelExportColumn
                  key={column}
                  field="reserveday"
                  title={t("Day")}
                />
              );
            }
            if (column == "Start Time") {
              return (
                <ExcelExportColumn
                  key={column}
                  field="starttime"
                  title={t("Start Time")}
                />
              );
            }
            if (column == "End Time") {
              return (
                <ExcelExportColumn
                  key={column}
                  field="endtime"
                  title={t("End Time")}
                />
              );
            }
            if (column == "Code") {
              return (
                <ExcelExportColumn
                  key={column}
                  field="activitycode.code"
                  title={t("Code")}
                />
              );
            }
            if (column == "Activity") {
              return (
                <ExcelExportColumn
                  key={column}
                  field="activitycode.name"
                  title={t("Activity")}
                />
              );
            }
            if (column == "Activity Type") {
              return (
                <ExcelExportColumn
                  key={column}
                  field="activitytype"
                  title={t("Activity Type")}
                />
              );
            }
            if (column == "Tags") {
              return (
                <ExcelExportColumn
                  key={column}
                  field="tagstring"
                  title={t("Tags")}
                />
              );
            }
            if (column == "Educator 1") {
              return (
                <ExcelExportColumn
                  key={column}
                  field="educator1.name"
                  title={t("Educator 1")}
                />
              );
            }
            if (column == "Educator 2") {
              return (
                <ExcelExportColumn
                  key={column}
                  field="educator2.name"
                  title={t("Educator 2")}
                />
              );
            }
            if (column == "Client") {
              return (
                <ExcelExportColumn
                  key={column}
                  field="client.name"
                  title={t("Client")}
                />
              );
            }
            if (column == "Observer") {
              return (
                <ExcelExportColumn
                  key={column}
                  field="observer.name"
                  title={t("Observer")}
                />
              );
            }
            if (column == "Beneficiary") {
              return (
                <ExcelExportColumn
                  key={column}
                  field="beneficiary.name"
                  title={t("Beneficiary")}
                />
              );
            }
            if (column == "Responsible") {
              return (
                <ExcelExportColumn
                  key={column}
                  field="responsible.name"
                  title={t("Responsible")}
                />
              );
            }
            if (column == "No of Participants") {
              return (
                <ExcelExportColumn
                  key={column}
                  field="participantcount"
                  title={t("No of Participants")}
                />
              );
            }
            if (column == "No of Educator") {
              return (
                <ExcelExportColumn
                  key={column}
                  field="educatorcount"
                  title={t("No of Educator")}
                />
              );
            }
            if (column == "No Participants Real") {
              return (
                <ExcelExportColumn
                  key={column}
                  field="realparticipantcount"
                  title={t("No Participants Real")}
                />
              );
            }
            if (column == "Tutor") {
              return (
                <ExcelExportColumn
                  key={column}
                  field="tutor"
                  title={t("Tutor")}
                />
              );
            }
            if (column == "Specific Audience") {
              return (
                <ExcelExportColumn
                  field="specificaudiencestring"
                  title={t("Specific Audience")}
                />
              );
            }
            if (column == "Grade") {
              return (
                <ExcelExportColumn
                  key={column}
                  field="grade"
                  title={t("Grade")}
                />
              );
            }
            if (column == "Sales Order") {
              return (
                <ExcelExportColumn
                  key={column}
                  field="salesorderstring"
                  title={t("Sales order")}
                />
              );
            }
            if (column == "Comments") {
              return (
                <ExcelExportColumn
                  key={column}
                  field="comments"
                  title={t("Comments")}
                />
              );
            }
            if (column == "Int. Comments") {
              return (
                <ExcelExportColumn
                  key={column}
                  field="internalcomment"
                  title={t("Internal Comments")}
                />
              );
            }
            if (column == "Location") {
              return (
                <ExcelExportColumn
                  key={column}
                  field="location"
                  title={t("Location")}
                />
              );
            }
            if (column == "Status") {
              return (
                <ExcelExportColumn
                  key={column}
                  field="reservestatus"
                  title={t("Status")}
                />
              );
            }
          })}
        </ExcelExport>
      ) : (
        <ExcelExport
          data={
            handelSelectedCount().length > 0
              ? handelSelectedCount()
              : reservationList
          }
          fileName="Reservation List.xlsx"
          ref={_exporter}
        >
          <ExcelExportColumn field="reserveid" title={t("ID")} />
          <ExcelExportColumn
            field="isconsecutivestring"
            title={t("Is Consecutive")}
          />
          <ExcelExportColumn field="shiftlabel" title={t("Shift")} />
          <ExcelExportColumn field="ambreservationid" title={t("AMB Id")} />
          <ExcelExportColumn field="reservedate" title={t("Date")} />
          <ExcelExportColumn field="reserveday" title={t("Day")} />
          <ExcelExportColumn field="starttime" title={t("Start Time")} />
          <ExcelExportColumn field="endtime" title={t("End Time")} />
          <ExcelExportColumn field="activitycode.code" title={t("Code")} />
          <ExcelExportColumn field="activitycode.name" title={t("Activity")} />
          <ExcelExportColumn field="activitytype" title={t("Activity Type")} />
          <ExcelExportColumn field="tagstring" title={t("Tags")} />
          <ExcelExportColumn field="educator1.name" title={t("Educator 1")} />
          <ExcelExportColumn field="educator2.name" title={t("Educator 2")} />
          <ExcelExportColumn field="client.name" title={t("Client")} />
          <ExcelExportColumn field="observer.name" title={t("Observer")} />
          <ExcelExportColumn
            field="beneficiary.name"
            title={t("Beneficiary")}
          />
          <ExcelExportColumn
            field="responsible.name"
            title={t("Responsible")}
          />
          <ExcelExportColumn
            field="participantcount"
            title={t("No of Participants")}
          />
          <ExcelExportColumn
            field="educatorcount"
            title={t("No of Educator")}
          />
          <ExcelExportColumn
            field="realparticipantcount"
            title={t("No Participants Real")}
          />
          <ExcelExportColumn
            field="salesorderstring"
            title={t("Sales order")}
          />
          <ExcelExportColumn field="tutor" title={t("Tutor")} />
          <ExcelExportColumn
            field="specificaudiencestring"
            title={t("Specific Audience")}
          />
          <ExcelExportColumn field="grade" title={t("Grade")} />
          <ExcelExportColumn field="comments" title={t("Comments")} />
          <ExcelExportColumn
            field="internalcomment"
            title={t("Internal Comments")}
          />
          <ExcelExportColumn field="location" title={t("Location")} />
          <ExcelExportColumn field="reservestatus" title={t("Status")} />
        </ExcelExport>
      )}
      {/* ============ download xls start ========== */}
      <LocalizationProvider
        language={i18n.language == "en" ? "en-EN" : "es-ES"}
      >
        <IntlProvider locale={i18n.language == "en" ? "en" : "es"}>
          {columns.length > 0 ? (
            <Grid
              style={{ height: screenHeight }}
              data={search ? filterData() : result}
              filterable={true}
              sortable={true}
              groupable={true}
              onDataStateChange={onDataStateChange}
              {...dataState}
              //pageable = {true}
              //total = {products.length}
              reorderable
              // resizable
            >
              <GridColumn
                field="id"
                headerCell={SelectRecordHeader}
                cell={SelectRecordRow}
                filterable={false}
                sortable={false}
                width="70px"
              />
              <GridColumn
                field="reserveid"
                title={t("ID")}
                cell={primaryBlockHtml}
                width="200px"
              />
              {columns.map((column) => {
                if (column === "Shift") {
                  return (
                    <GridColumn
                      key={column}
                      field="shiftlabel"
                      title={t("Shift")}
                      width="100px"
                    />
                  );
                }
                if (column == "AMB ID") {
                  return (
                    <GridColumn
                      key={column}
                      field="ambreservationid"
                      title={t("AMB Id")}
                      width="200px"
                    />
                  );
                }
                if (column == "Date") {
                  return (
                    <GridColumn
                      key={column}
                      field="startdate"
                      cell={reserveDateBlockHTML}
                      title={t("Date")}
                      width="200px"
                    />
                  );
                }
                if (column == "Day") {
                  return (
                    <GridColumn
                      key={column}
                      field="reserveday"
                      title={t("Day")}
                      width="200px"
                    />
                  );
                }
                if (column == "Start Time") {
                  return (
                    <GridColumn
                      key={column}
                      field="starttime"
                      title={t("Start Time")}
                      width="200px"
                    />
                  );
                }
                if (column == "End Time") {
                  return (
                    <GridColumn
                      key={column}
                      field="endtime"
                      title={t("End Time")}
                      width="200px"
                    />
                  );
                }
                if (column == "Code") {
                  return (
                    <GridColumn
                      key={column}
                      field="activitycode.code"
                      title={t("Code")}
                      width="200px"
                    />
                  );
                }
                if (column == "Activity") {
                  return (
                    <GridColumn
                      key={column}
                      field="activitycode.name"
                      title={t("Activity")}
                      width="300px"
                    />
                  );
                }
                if (column == "Activity Type") {
                  return (
                    <GridColumn
                      key={column}
                      field="activitytype"
                      title={t("Activity Type")}
                      width="200px"
                    />
                  );
                }
                if (column == "Tags") {
                  return (
                    <GridColumn
                      key={column}
                      field="tagstring"
                      title={t("Tags")}
                      width="200px"
                      //cell={reserveTagBlockHtml}
                    />
                  );
                }
                if (column == "Educator 1") {
                  return (
                    <GridColumn
                      key={column}
                      field="educator1.name"
                      title={t("Educator 1")}
                      cell={educatorOneblockHTML}
                      width="250px"
                    />
                  );
                }
                if (column == "Educator 2") {
                  return (
                    <GridColumn
                      key={column}
                      field="educator2.name"
                      title={t("Educator 2")}
                      cell={educatorTwoblockHTML}
                      width="250px"
                    />
                  );
                }
                if (column == "Client") {
                  return (
                    <GridColumn
                      key={column}
                      field="client.name"
                      title={t("Client")}
                      cell={clientBlockHTML}
                      width="200px"
                    />
                  );
                }
                if (column == "Observer") {
                  return (
                    <GridColumn
                      key={column}
                      field="observer.name"
                      title={t("Observer")}
                      cell={observerHTML}
                      width="200px"
                    />
                  );
                }
                if (column == "Beneficiary") {
                  return (
                    <GridColumn
                      key={column}
                      field="beneficiary.name"
                      title={t("Beneficiary")}
                      cell={benificiaryHTML}
                      width="200px"
                    />
                  );
                }
                if (column == "Responsible") {
                  return (
                    <GridColumn
                      field="responsible.name"
                      title={t("Responsible")}
                      cell={responsibleHTML}
                      width="200px"
                    />
                  );
                }
                if (column == "No of Participants") {
                  return (
                    <GridColumn
                      key={column}
                      field="participantcount"
                      title={t("No of Participants")}
                      width="200px"
                    />
                  );
                }
                if (column == "No of Educator") {
                  return (
                    <GridColumn
                      key={column}
                      field="educatorcount"
                      title={t("No of Educator")}
                      width="200px"
                    />
                  );
                }
                if (column == "No Participants Real") {
                  return (
                    <GridColumn
                      key={column}
                      field="realparticipantcount"
                      title={t("No Participants Real")}
                      width="200px"
                    />
                  );
                }
                if (column == "Tutor") {
                  return (
                    <GridColumn
                      key={column}
                      field="tutor"
                      title={t("Tutor")}
                      width="180px"
                    />
                  );
                }
                if (column == "Specific Audience") {
                  return (
                    <GridColumn
                      key={column}
                      field="specificaudiencestring"
                      title={t("Specific Audience")}
                      width="180px"
                    />
                  );
                }
                if (column == "Grade") {
                  return (
                    <GridColumn
                      key={column}
                      field="grade"
                      title={t("Grade")}
                      width="180px"
                    />
                  );
                }
                if (column == "Sales Order") {
                  return (
                    <GridColumn
                      key={column}
                      field="salesorderstring"
                      cell={salesOrderHtml}
                      title={t("Sales order")}
                      width="180px"
                    />
                  );
                }
                if (column == "Comments") {
                  return (
                    <GridColumn
                      key={column}
                      field="comments"
                      title={t("Comments")}
                      cell={commentHtml}
                      width="200px"
                    />
                  );
                }
                if (column == "InternalComments") {
                  return (
                    <GridColumn
                      key={column}
                      field="internalcomment"
                      title={t("Int. Comments")}
                      cell={insternalCommentsHtml}
                      width="200px"
                    />
                  );
                }
                if (column == "Location") {
                  return (
                    <GridColumn
                      key={column}
                      field="location"
                      title={t("Location")}
                      cell={locationHTML}
                      width="200px"
                    />
                  );
                }
                if (column == "Zipcode") {
                  return (
                    <GridColumn
                      key={column}
                      field="zipcode"
                      title={t("Zipcode")}
                      width="200px"
                    />
                  );
                }
                if (column == "Status") {
                  return (
                    <GridColumn
                      key={column}
                      cell={statusHTML}
                      field={t("reservestatus")}
                      title={t("Status")}
                      width="200px"
                    />
                  );
                }
              })}
              {/* {isTemplateReserveFilter == true ? (
                <GridColumn
                  field="reserveid"
                  title={t("Template")}
                  cell={primaryBlockTemplateHtml}
                  width="400px"
                />
              ) : (
                ''
              )} */}
              {/* {showReconfirmColumn && */}
              {(userInfo?.role?.name == "admin" ||
                userInfo?.role?.name == "manager") && (
                <GridColumn
                  cell={reConfirmHtml}
                  field="reconfirmation"
                  title={t("Reconfirmation")}
                  width="200px"
                />
              )}
            </Grid>
          ) : (
            <Grid
              style={{ height: screenHeight }}
              data={search ? filterData() : result}
              filterable={true}
              sortable={true}
              groupable={true}
              onDataStateChange={onDataStateChange}
              {...dataState}
              //pageable = {true}
              //total = {products.length}
              reorderable
              // resizable
            >
              <GridColumn
                field="id"
                headerCell={SelectRecordHeader}
                cell={SelectRecordRow}
                filterable={false}
                sortable={false}
                width="70px"
              />
              <GridColumn
                field="reserveid"
                title={t("ID")}
                cell={primaryBlockHtml}
                width="200px"
              />
              <GridColumn field="shiftlabel" title={t("Shift")} width="100px" />
              <GridColumn
                field="ambreservationid"
                title={t("AMB Id")}
                width="200px"
              />
              <GridColumn
                field="startdate"
                cell={reserveDateBlockHTML}
                title={t("Date")}
                width="200px"
              />
              <GridColumn field="reserveday" title={t("Day")} width="200px" />
              <GridColumn
                field="starttime"
                title={t("Start Time")}
                width="200px"
              />
              <GridColumn field="endtime" title={t("End Time")} width="200px" />
              <GridColumn
                field="activitycode.code"
                title={t("Code")}
                width="200px"
              />
              <GridColumn
                field="activitycode.name"
                title={t("Activity")}
                width="300px"
              />
              <GridColumn
                field="activitytype"
                title={t("Activity Type")}
                width="200px"
              />
              <GridColumn
                field="tagstring"
                title={t("Tags")}
                width="200px"
                //cell={reserveTagBlockHtml}
              />
              <GridColumn
                field="educator1.name"
                title={t("Educator 1")}
                cell={educatorOneblockHTML}
                width="250px"
              />
              <GridColumn
                field="educator2.name"
                title={t("Educator 2")}
                cell={educatorTwoblockHTML}
                width="250px"
              />
              <GridColumn
                field="client.name"
                title={t("Client")}
                cell={clientBlockHTML}
                width="200px"
              />
              <GridColumn
                field="observer.name"
                title={t("Observer")}
                cell={observerHTML}
                width="200px"
              />
              <GridColumn
                field="beneficiary.name"
                title={t("Beneficiary")}
                cell={benificiaryHTML}
                width="200px"
              />
              <GridColumn
                field="responsible.name"
                title={t("Responsible")}
                cell={responsibleHTML}
                width="200px"
              />
              <GridColumn
                field="participantcount"
                title={t("No of Participants")}
                width="200px"
              />
              <GridColumn
                field="educatorcount"
                title={t("No of Educator")}
                width="200px"
              />
              <GridColumn
                field="realparticipantcount"
                title={t("No Participants Real")}
                width="200px"
              />
              <GridColumn field="tutor" title={t("Tutor")} width="180px" />
              <GridColumn
                field="specificaudiencestring"
                title={t("Specific Audience")}
                width="180px"
              />
              <GridColumn field="grade" title={t("Grade")} width="180px" />
              <GridColumn
                field="salesorderstring"
                cell={salesOrderHtml}
                title={t("Sales order")}
                width="180px"
              />
              <GridColumn
                field="comments"
                title={t("Comments")}
                cell={commentHtml}
                width="200px"
              />
              <GridColumn
                field="internalcomment"
                title={t("Int. Comments")}
                cell={insternalCommentsHtml}
                width="200px"
              />
              <GridColumn
                field="location"
                title={t("Location")}
                cell={locationHTML}
                width="200px"
              />
              <GridColumn field="zipcode" title={t("Zipcode")} width="200px" />
              <GridColumn
                cell={statusHTML}
                field={t("reservestatus")}
                title={t("Status")}
                width="200px"
              />
              {(userInfo?.role?.name == "admin" ||
                userInfo?.role?.name == "manager") && (
                <GridColumn
                  cell={reConfirmHtml}
                  field="reconfirmation"
                  title={t("Reconfirmation")}
                  width="200px"
                />
              )}
            </Grid>
          )}
        </IntlProvider>
      </LocalizationProvider>
    </div>
  );
};
export default ReservationList;
