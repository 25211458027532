/* eslint-disable */
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import PersonalInfoTab from "../Tabs/PersonalInfoTab";
import PasswordTab from "../Tabs/PasswordTab";
import PrivacyTab from "../Tabs/PrivacyTab";
import SurveyFeedbackTab from "../Tabs/SurveyFeedbackTab";
import CalenderTab from "../Tabs/CalenderTab";
import ActivityTab from "../Tabs/ActivityTab";

/*import url and gateway methods */
import { getData } from "utils/Gateway";
import * as url from "urlhelpers/UrlHelper";
import SaveSchedulePopup from "../Popup/SaveSchedulePopup";
import EditSchedulePopup from "../Popup/EditSchedulePopup";
import SettingsTab from "../Tabs/SettingsTab";

const UserProfileArea = () => {
  const params = useParams();
  const token = localStorage.getItem("token");

  const [userData, setUserData] = useState(null);
  const [reloadCalender, setReloadCalender] = useState(false);

  //function for get user details
  const getUserDetails = async () => {
    try {
      const requestUrl =
        url.API_BASEURL +
        url.API_GET_USER_DETAILS +
        `/${params.id}?token=${token}`;

      const response = await getData(requestUrl);

      console.log("Response in profilearea------>", response);

      if (response.status) {
        setUserData(response.data);
      } else {
        setUserData(null);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for after passowrd update
  const afterUpdatePasswordTab = () => {
    getUserDetails();
  };

  useEffect(() => {
    getUserDetails();
    setReloadCalender(true);
  }, [params.id]);

  return (
    <section className="profile_tabcontent_sec">
      <div className="container-fluid px-4">
        <div className="tab-content">
          {/* ------ personal tab start ------ */}
          <div className="tab-pane show active fade" id="personel_info">
            <PersonalInfoTab
              userData={userData}
              afterUpdatePasswordTab={afterUpdatePasswordTab}
            />
          </div>
          {/* ------ personal tab end ------ */}

          {/* ------ password tab start ------ */}
          <div className="tab-pane fade" id="password">
            <PasswordTab
              userData={userData}
              afterUpdatePasswordTab={afterUpdatePasswordTab}
            />
          </div>
          {/* ------ password tab end ------ */}

          {/* ------ privacy tab start ------ */}
          {/* <div className="tab-pane fade" id="privacy">
            <PrivacyTab />
          </div> */}
          {/* ------ privacy tab end ------ */}

          {/* ------ survey tab start ------ */}
          <div className="tab-pane fade" id="surveyfeedback">
            <SurveyFeedbackTab userData={userData} />
          </div>
          {/* ------ survey tab end ------ */}

          {/* ------ calender tab start ------ */}
          <div className="tab-pane fade" id="calender">
            <CalenderTab
              userData={userData}
              reloadCalender={reloadCalender}
              setReloadCalender={setReloadCalender}
            />
          </div>

          {/* ------ calender tab end ------ */}

          {/* ------ activity tab start ------ */}
          <div className="tab-pane fade" id="activity">
            <ActivityTab userData={userData} />
          </div>
          {/* ------ activity tab end ------ */}

          {/* <!-- ========== Start settings tab Section ========== --> */}
          <div className="tab-pane fade" id="settings">
            <SettingsTab
              userData={userData}
              afterUpdatePasswordTab={afterUpdatePasswordTab}
            />
          </div>
          {/* <!-- ========== End settings tab Section ========== --> */}
        </div>
      </div>

      {/* ======= calendar popup start ======= */}
      <SaveSchedulePopup
        reloadCalender={reloadCalender}
        setReloadCalender={setReloadCalender}
      />

      <EditSchedulePopup />
      {/* ======= calendar popup end======= */}
    </section>
  );
};

export default UserProfileArea;
